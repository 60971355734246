import Home from '../../views/Home.vue';

export default {
  path: '/extension',
  name: 'extension',
  component: Home,
  redirect: '/extension/couponList',
  meta: {
    name: '推广',
    title: '推广管理',
    rules: 1,
  },
  children: [
    {
      path: '/extension/activity',
      name: 'extension-activity',
      redirect: '/extension/activity/activityList',
      component: () => import('@/sports/activity/index'),
      meta: {
        name: '赛程活动',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/activity/activityList',
          name: 'extension-activity-activityList',
          component: () => import('@/sports/activity/activityList'),
          meta: {
            name: '活动列表',
            rules: 1,
          },
        },
        {
          path: '/extension/activity/addActivity',
          name: 'extension-activity-addActivity',
          component: () => import('@/sports/activity/addActivity'),
          meta: {
            name: '添加活动',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/activity/editActivity',
          name: 'extension-activity-editActivity',
          component: () => import('@/sports/activity/addActivity'),
          meta: {
            relation: 'extension-activity-activityList',
            name: '活动详情',
            rules: 0,
            form: 1,
          },
        },
        {
          path: '/extension/activity/monthlyFinals',
          name: 'extension-activity-monthlyFinals',
          component: () => import('@/sports/activity/monthlyFinals'),
          hidden: true,
          meta: {
            relation: 'extension-activity-activityList',
            name: '月决赛',
            rules: 0,
          },
        },
        {
          path: '/extension/activity/ranksList',
          name: 'extension-activity-ranksList',
          component: () => import('@/sports/activity/ranksList'),
          meta: {
            name: '队伍列表',
            rules: 1,
          },
        },
        {
          path: '/extension/activity/scheduleList',
          name: 'extension-activity-scheduleList',
          component: () => import('@/sports/activity/scheduleList'),
          meta: {
            name: '赛程列表',
            rules: 1,
          },
        },
        {
          path: '/extension/activity/draw',
          name: 'extension-activity-draw',
          component: () => import('@/sports/activity/draw'),
          meta: {
            relation: 'extension-activity-scheduleList',
            name: '抽签晋级',
            rules: 0,
          },
        },
        {
          path: '/extension/activity/scheduleAward',
          name: 'extension-activity-scheduleAward',
          component: () => import('@/sports/activity/scheduleAward.vue'),
          meta: {
            relation: 'extension-activity-scheduleList',
            name: '赛程颁奖',
            rules: 0,
          },
        },
        {
          path: '/extension/activity/addSchedule',
          name: 'extension-activity-addSchedule',
          component: () => import('@/sports/activity/addSchedule'),
          meta: {
            name: '添加赛程',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/activity/editSchedule',
          name: 'extension-activity-editSchedule',
          component: () => import('@/sports/activity/addSchedule'),
          meta: {
            relation: 'extension-activity-scheduleList',
            name: '赛程详情',
            rules: 0,
            form: 1,
          },
        },
      ],
    },
    {
      path: '/extension/vote',
      name: 'extension-vote',
      redirect: '/extension/vote/voteList',
      component: () => import('@/sports/vote/index'),
      meta: {
        name: '投票管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/vote/voteList',
          name: 'extension-vote-voteList',
          component: () => import('@/sports/vote/voteList'),
          meta: {
            name: '投票列表',
            rules: 1,
          },
        },
        {
          path: '/extension/vote/addVote',
          name: 'extension-vote-addVote',
          component: () => import('@/sports/vote/addVote'),
          meta: {
            name: '添加投票',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/vote/editVote',
          name: 'extension-vote-editVote',
          component: () => import('@/sports/vote/addVote'),
          meta: {
            relation: 'extension-vote-voteList',
            name: '投票详情',
            rules: 0,
            form: 1,
          },
        },
        {
          path: '/extension/vote/voteResult',
          name: 'extension-vote-voteResult',
          component: () => import('@/sports/vote/voteResult'),
          meta: {
            name: '投票结果',
            rules: 1,
          },
        },
      ],
    },
    {
      path: '/extension/promoterList',
      name: 'extension-promoterList',
      component: () => import('@/O2O/extension/promoterList'),
      meta: {
        name: '推广员列表',
        rules: 1,
      },
    },
    {
      path: '/extension/promoterDetail',
      name: 'extension-promoterDetail',
      component: () => import('@/O2O/extension/promoterDetail'),
      meta: {
        name: '推广员详情',
        rules: 0,
        relation: 'extension-promoterList',
        form: 1,
      },
    },
    {
      path: '/extension/promotionAudit',
      name: 'extension-promotionAudit',
      component: () => import('@/O2O/extension/promotionAudit'),
      meta: {
        name: '推广审核',
        rules: 1,
      },
    },
    {
      path: '/extension/marketingCenter',
      name: 'extension-marketingCenter',
      component: () => import('@/views/extension/MarketingCenter'),
      meta: {
        name: '营销中心',
        rules: 1,
      },
    },
    {
      path: '/extension/couponList',
      name: 'extension-couponList',
      component: () => import('@/views/extension/CouponList'),
      meta: {
        name: '优惠券列表',
        rules: 1,
      },
    },
    {
      path: '/extension/wholesaleList',
      name: 'extension-wholesaleList',
      component: () => import('@/views/extension/wholesaleList'),
      meta: {
        name: '批发列表',
        rules: 1,
      },
    },
    {
      path: '/extension/couponAdd',
      name: 'extension-couponAdd',
      component: () => import('@/views/extension/CouponAdd'),
      meta: {
        name: '添加优惠券',
        relation: 'extension-couponList',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/extension/couponEdit',
      name: 'extension-couponEdit',
      component: () => import('@/views/extension/CouponAdd'),
      meta: {
        name: '编辑优惠券',
        relation: 'extension-couponList',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/extension/enclosureList',
      name: 'extension-enclosureList',
      component: () => import('@/views/extension/EnclosureList'),
      meta: {
        name: '地理围栏列表',
        rules: 1,
      },
    },
    {
      path: '/extension/addEnclosure',
      name: 'extension-addEnclosure',
      component: () => import('@/views/extension/AddEnclosure'),
      meta: {
        name: '添加地址围栏',
        relation: 'extension-enclosureList',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/extension/editEnclosure',
      name: 'extension-editEnclosure',
      component: () => import('@/views/extension/AddEnclosure'),
      meta: {
        name: '编辑地址围栏',
        relation: 'extension-enclosureList',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/extension/editEnclosurePush',
      name: 'extension-editEnclosurePush',
      component: () => import('@/views/extension/AddEnclosurePush'),
      meta: {
        name: '编辑围栏推送',
        relation: 'extension-enclosureList',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/extension/appletLiveBroadcast',
      name: 'extension-appletLiveBroadcast',
      component: () => import('@/views/extension/appletLiveBroadcast/index.vue'),
      meta: {
        name: '小程序直播',
        rules: 1,
      },
    },
    {
      path: '/extension/addAppletLiveBroadcast',
      name: 'extension-addAppletLiveBroadcast',
      component: () => import('@/views/extension/appletLiveBroadcast/components/add.vue'),
      meta: {
        name: '添加小程序直播',
        relation: 'extension-appletLiveBroadcast',
        rules: 0,
        form: 0,
      },
    },
    {
      path: '/extension/liveList',
      name: 'liveList',
      component: () => import('@/views/extension/appletLiveBroadcast/components/liveList.vue'),
      meta: {
        name: '小程序直播',
        relation: 'appletLiveBroadcast',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/extension/console',
      name: 'extension-addAppletLiveBroadcast',
      component: () => import('@/views/extension/appletLiveBroadcast/components/console.vue'),
      meta: {
        name: '直播间控制台',
        relation: 'extension-appletLiveBroadcast',
        rules: 0,
        form: 0,
      },
    },
    {
      path: '/extension/group',
      name: 'extension-group',
      redirect: '/extension/group/list',
      component: () => import('@/views/extension/group/index'),
      meta: {
        name: '多人拼团',
        rules: 1,
      },
      children: [
        {
          path: '/extension/group/list',
          name: 'group-list',
          component: () => import('@/views/extension/group/list/index'),
          meta: {
            relation: 'extension-group',
            name: '多人拼团',
          },
        },
        {
          path: '/extension/group/add',
          name: 'group-add',
          component: () => import('@/views/extension/group/add/index'),
          meta: {
            relation: 'extension-group',
            name: '创建拼团',
            form: 1,
          },
        },
        {
          path: '/extension/group/detail',
          name: 'group-add',
          component: () => import('@/views/extension/group/add/index'),
          meta: {
            relation: 'extension-group',
            name: '拼团详情',
          },
        },
      ],
    },
    {
      path: '/beauty/curriculum',
      name: 'beauty-curriculum',
      redirect: '/beauty/curriculum/list',
      component: () => import('@/beauty/curriculum/index'),
      meta: {
        name: '课程管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/beauty/curriculum/list',
          name: 'beauty-curriculum-list',
          component: () => import('@/beauty/curriculum/list'),
          meta: {
            name: '课程列表',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/beauty/curriculum/add',
          name: 'beauty-curriculum-add',
          component: () => import('@/beauty/curriculum/add'),
          meta: {
            name: '添加课程',
            rules: 1,
            form: 1,
            selected: 0,
          },
        },
        {
          path: '/beauty/curriculum/info',
          name: 'beauty-curriculum-info',
          component: () => import('@/beauty/curriculum/add'),
          meta: {
            name: '课程详情',
            relation: 'beauty-curriculum-list',
            rules: 0,
            form: 1,
          },
        },
        {
          path: '/beauty/curriculum/catalogue',
          name: 'beauty-curriculum-catalogue',
          component: () => import('@/beauty/curriculum/catalogue'),
          meta: {
            name: '课程目录',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/beauty/curriculum/order',
          name: 'beauty-curriculum-order',
          component: () => import('@/beauty/curriculum/order'),
          meta: {
            name: '课程订单',
            rules: 1,
            selected: 0,
          },
        },
      ],
    },
    {
      path: '/beauty/distribution',
      name: 'beauty-distribution',
      redirect: '/beauty/distribution/distributor',
      component: () => import('@/beauty/distribution/Index'),
      meta: {
        name: 'CPS分销',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/beauty/distribution/distributor',
          name: 'beauty-distribution-distributor',
          component: () => import('@/beauty/distribution/Distributor'),
          meta: {
            name: '分销员列表',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/beauty/distribution/distributorDetail',
          name: 'beauty-distribution-distributorDetail',
          component: () => import('@/beauty/distribution/DistributorDetail'),
          meta: {
            name: '分销员详情',
            relation: 'beauty-distribution-distributor',
            rules: 0,
          },
        },
        {
          path: '/beauty/distribution/basicSet',
          name: 'beauty-distribution-basicSet',
          component: () => import('@/beauty/distribution/BasicSet'),
          meta: {
            name: '分销基本设置',
            rules: 1,
            form: 1,
            selected: 0,
          },
        },
        {
          path: '/beauty/distribution/goodsSet',
          name: 'beauty-distribution-goodsSet',
          component: () => import('@/beauty/distribution/GoodsSet'),
          meta: {
            name: '分销商品设置',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/beauty/distribution/serviceSet',
          name: 'beauty-distribution-serviceSet',
          component: () => import('@/beauty/distribution/serviceSet'),
          meta: {
            name: '分销服务设置',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/beauty/distribution/clearing',
          name: 'beauty-distribution-clearing',
          component: () => import('@/beauty/distribution/Clearing'),
          meta: {
            name: '分销结算',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/beauty/distribution/relation',
          name: 'beauty-distribution-relation',
          component: () => import('@/beauty/distribution/Relation'),
          meta: {
            name: '分销客户关系',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/beauty/distribution/auditList',
          name: 'beauty-distribution-auditList',
          component: () => import('@/beauty/distribution/AuditList'),
          meta: {
            name: '分销审核',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/beauty/distribution/withdrawalReview',
          name: 'beauty-distribution-WithdrawalReview',
          component: () => import('@/beauty/distribution/WithdrawalReview'),
          meta: {
            name: '提现审核',
            rules: 1,
            selected: 0,
          },
        },
      ],
    },
    {
      path: '/extension/distribution',
      name: 'extension-distribution',
      redirect: '/extension/distribution/distributor',
      component: () => import('@/views/extension/distribution/Index'),
      meta: {
        name: 'CPS分销',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/distribution/distributor',
          name: 'extension-distribution-distributor',
          component: () => import('@/views/extension/distribution/Distributor'),
          meta: {
            name: '分销员列表',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/extension/distribution/distributorDetail',
          name: 'extension-distribution-distributorDetail',
          component: () => import('@/views/extension/distribution/DistributorDetail'),
          meta: {
            name: '分销员详情',
            relation: 'extension-distribution-distributor',
            rules: 0,
          },
        },
        {
          path: '/extension/distribution/basicSet',
          name: 'extension-distribution-basicSet',
          component: () => import('@/views/extension/distribution/BasicSet'),
          meta: {
            name: '分销基本设置',
            rules: 1,
            form: 1,
            selected: 0,
          },
        },
        {
          path: '/extension/distribution/goodsSet',
          name: 'extension-distribution-goodsSet',
          component: () => import('@/views/extension/distribution/GoodsSet'),
          meta: {
            name: '分销商品设置',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/extension/distribution/clearing',
          name: 'extension-distribution-clearing',
          component: () => import('@/views/extension/distribution/Clearing'),
          meta: {
            name: '分销结算',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/extension/distribution/relation',
          name: 'extension-distribution-relation',
          component: () => import('@/views/extension/distribution/Relation'),
          meta: {
            name: '分销客户关系',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/extension/distribution/auditList',
          name: 'extension-distribution-auditList',
          component: () => import('@/views/extension/distribution/AuditList'),
          meta: {
            name: '分销审核',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/extension/distribution/withdrawalReview',
          name: 'extension-distribution-WithdrawalReview',
          component: () => import('@/views/extension/distribution/WithdrawalReview'),
          meta: {
            name: '提现审核',
            rules: 1,
            selected: 0,
          },
        },
      ],
    },
    {
      path: '/extension/forum',
      name: 'extension-forum',
      redirect: '/extension/forum/list',
      component: () => import('@/views/extension/forum/Index'),
      meta: {
        name: '论坛管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/forum/list',
          name: 'extension-forum-list',
          component: () => import('@/views/extension/forum/List'),
          meta: {
            name: '论坛列表',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/extension/forum/add',
          name: 'extension-forum-add',
          component: () => import('@/views/extension/forum/Add'),
          meta: {
            name: '发布论坛',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/forum/detail',
          name: 'extension-forum-detail',
          component: () => import('@/views/extension/forum/Add'),
          meta: {
            name: '查看论坛',
            relation: 'extension-forum-list',
            rules: 0,
            form: 1,
          },
        },
        {
          path: '/extension/forum/type',
          name: 'extension-forum-type',
          component: () => import('@/views/extension/forum/Type'),
          meta: {
            name: '论坛分类',
            rules: 1,
          },
        },
        {
          path: '/extension/forum/setting',
          name: 'extension-forum-setting',
          component: () => import('@/views/extension/forum/Setting'),
          meta: {
            name: '论坛设置',
            rules: 1,
          },
        },
      ],
    },
    {
      path: '/extension/community',
      name: 'extension-community',
      redirect: '/extension/community/list',
      component: () => import('@/views/extension/forum/Index'),
      meta: {
        name: '社区种草',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/community/list',
          name: 'extension-community-list',
          component: () => import('@/views/extension/community/List'),
          meta: {
            name: '论坛列表',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/extension/community/add',
          name: 'extension-community-add',
          component: () => import('@/views/extension/community/Add'),
          meta: {
            name: '发布论坛',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/community/detail',
          name: 'extension-community-detail',
          component: () => import('@/views/extension/community/Add'),
          meta: {
            name: '查看论坛',
            relation: 'extension-forum-list',
            rules: 0,
            form: 1,
          },
        },
        {
          path: '/extension/community/type',
          name: 'extension-community-type',
          component: () => import('@/views/extension/community/Type'),
          meta: {
            name: '论坛分类',
            rules: 1,
          },
        },
        {
          path: '/extension/community/setting',
          name: 'extension-community-setting',
          component: () => import('@/views/extension/community/Setting'),
          meta: {
            name: '论坛设置',
            rules: 1,
          },
        },
      ],
    },
    {
      path: '/extension/MarketingList',
      name: 'extension-MarketingList',
      component: () => import('@/views/extension/MarketingList'),
      meta: {
        name: '智能营销',
        rules: 1,
      },
    },
    {
      path: '/extension/shareholderDividends',
      name: 'extension-shareholderDividends',
      redirect: '/extension/shareholderDividends/list',
      component: () => import('@/views/extension/regionalShareholders/index'),
      meta: {
        name: '区域股东分红',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/shareholderDividends/list',
          name: 'extension-shareholderDividends-list',
          component: () => import('@/views/extension/regionalShareholders/shareholderList'),
          meta: {
            name: '股东列表',
            rules: 1,
          },
        },
        {
          path: '/extension/shareholderDividends/addShareholderDividends',
          name: 'extension-addShareholderDividends-add',
          component: () => import('@/views/extension/regionalShareholders/addShareholderDividends'),
          meta: {
            name: '添加股东',
            rules: 1,
            form: 1,
          },
        },
      ],
    },
    {
      path: '/extension/regionalDividend',
      name: 'extension-regionalDividend',
      redirect: '/extension/regionalDividend/auditArea',
      component: () => import('@/views/extension/regionalDividend/index'),
      meta: {
        name: '区域分红',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/regionalDividend/auditArea',
          name: 'extension-regionalDividend-auditArea',
          component: () => import('@/views/extension/regionalDividend/auditArea'),
          meta: {
            name: '区域审核',
            rules: 1,
          },
        },
        {
          path: '/extension/regionalDividend/IdentityModification',
          name: 'extension-regionalDividend-IdentityModification',
          component: () => import('@/views/extension/regionalDividend/IdentityModification'),
          meta: {
            name: '身份修改',
            rules: 1,
          },
        },
        {
          path: '/extension/regionalDividend/basicSet',
          name: 'extension-regionalDividend-basicSet',
          component: () => import('@/views/extension/regionalDividend/basicSet'),
          meta: {
            name: '基础设置',
            rules: 1,
            form: 1,
          },
        },
      ],
    },
    {
      path: '/extension/shopReward',
      name: 'extension-shopReward',
      component: () => import('@/views/extension/ShopReward'),
      meta: {
        name: '购物奖励',
        rules: 1,
      },
    },
    {
      path: '/extension/customform',
      name: 'extension-customform',
      redirect: '/extension/customform/list',
      component: () => import('@/views/extension/customform/index.vue'),
      meta: {
        name: '自定义表单',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/customform/list',
          name: 'extension-customform-list',
          component: () => import('@/views/extension/customform/list.vue'),
          meta: {
            name: '表单列表',
            relation: 'extension-customform',
            rules: 1,
          },
        },
        {
          path: '/extension/customform/addform',
          name: 'extension-customform-addform',
          component: () => import('@/views/extension/customform/form.vue'),
          meta: {
            name: '添加表单',
            relation: 'extension-customform',
            form: 1,
            rules: 1,
          },
        },
        {
          path: '/extension/customform/editform',
          name: 'extension-customform-editform',
          component: () => import('@/views/extension/customform/form.vue'),
          meta: {
            name: '编辑表单',
            relation: 'extension-customform',
            form: 1,
          },
        },
        {
          path: '/extension/customform/datalist',
          name: 'extension-customform-datalist',
          component: () => import('@/views/extension/customform/dataList.vue'),
          meta: {
            name: '表单收集',
            relation: 'extension-customform',
          },
        },
        {
          path: '/extension/customform/douOrderList',
          name: 'extension-customform-douOrderList',
          component: () => import('@/views/extension/customform/douOrderList'),
          meta: {
            name: '抖音表单收集',
            relation: 'extension-customform',
            rules: 1,
          },
        },
      ],
    },
    {
      path: '/extension/VRshow',
      name: 'extension-VRshow',
      component: () => import('@/views/extension/VRshow/index.vue'),
      meta: {
        name: 'VR展示',
        rules: 1,
      },
    },
    {
      path: '/extension/VRadd',
      name: 'extension-VRadd',
      component: () => import('@/views/extension/VRshow/add.vue'),
      meta: {
        name: '添加VR',
        rules: 0,
        form: 1,
        relation: 'extension-VRshow',
      },
    },
    {
      path: '/extension/VRedit',
      name: 'extension-VRedit',
      component: () => import('@/views/extension/VRshow/add.vue'),
      meta: {
        name: '编辑VR',
        rules: 0,
        form: 1,
        relation: 'extension-VRshow',
      },
    },
    {
      path: '/extension/setMealList',
      name: 'extension-setMealList',
      component: () => import('@/beauty/setMeal/setMealList.vue'),
      meta: {
        name: '套餐管理',
        rules: 1,
      },
    },
    {
      path: '/extension/fixedSetMeal',
      name: 'extension-fixedSetMeal',
      component: () => import('@/beauty/setMeal/fixedSetMeal.vue'),
      meta: {
        name: '固定套餐',
        rules: 0,
        form: 1,
        relation: 'extension-setMealList',
      },
    },
    {
      path: '/extension/collocationSetMeal',
      name: 'extension-collocationSetMeal',
      component: () => import('@/beauty/setMeal/collocationSetMeal.vue'),
      meta: {
        name: '搭配套餐',
        rules: 0,
        form: 1,
        relation: 'extension-setMealList',
      },
    },
    {
      path: '/extension/crossWebsite',
      name: 'extension-crossWebsite',
      redirect: '/extension/crossWebsite/websiteDecoration',
      component: () => import('@/views/extension/crossWebsite/index'),
      meta: {
        name: '跨境PC官网',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/crossWebsite/websiteDecoration',
          name: 'extension-crossWebsite-websiteDecoration',
          component: () => import('@/views/extension/crossWebsite/websiteDecoration.vue'),
          meta: {
            name: '官网装修',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/crossWebsite/merchantClassification',
          name: 'extension-crossWebsite-merchantClassification',
          component: () => import('@/views/extension/crossWebsite/merchantClassification.vue'),
          meta: {
            name: '商家分类',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/crossWebsite/recommendMerchant',
          name: 'extension-crossWebsite-recommendMerchant',
          component: () => import('@/views/extension/crossWebsite/recommendMerchant.vue'),
          meta: {
            name: '推荐商家',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/crossWebsite/recommendNews',
          name: 'extension-crossWebsite-recommendNews',
          component: () => import('@/views/extension/crossWebsite/recommendNews.vue'),
          meta: {
            name: '推荐新闻',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/crossWebsite/recommendGoods',
          name: 'extension-crossWebsite-recommendGoods',
          component: () => import('@/views/extension/crossWebsite/recommendGoods.vue'),
          meta: {
            name: '推荐商品',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/crossWebsite/shortVideoClassification',
          name: 'extension-crossWebsite-shortVideoClassification',
          component: () => import('@/views/extension/crossWebsite/shortVideoClassification.vue'),
          meta: {
            name: '短视频分类',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/crossWebsite/shortVideoList',
          name: 'extension-crossWebsite-shortVideoList',
          component: () => import('@/views/extension/crossWebsite/shortVideoList.vue'),
          meta: {
            name: '短视频列表',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/crossWebsite/addShortVideo',
          name: 'extension-crossWebsite-addShortVideo',
          component: () => import('@/views/extension/crossWebsite/addShortVideo.vue'),
          meta: {
            name: '添加短视频',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/crossWebsite/editShortVideo',
          name: 'extension-crossWebsite-editShortVideo',
          component: () => import('@/views/extension/crossWebsite/addShortVideo.vue'),
          meta: {
            name: '编辑短视频',
            rules: 0,
            form: 1,
            relation: 'extension-crossWebsite-addShortVideo',
          },
        },
        {
          path: '/extension/crossWebsite/payConfig',
          name: 'extension-crossWebsite-payConfig',
          component: () => import('@/views/extension/crossWebsite/payConfig.vue'),
          meta: {
            name: '支付配置',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/crossWebsite/keywords',
          name: 'extension-crossWebsite-keywords',
          component: () => import('@/views/extension/crossWebsite/keywords.vue'),
          meta: {
            name: '关键词',
            rules: 1,
          },
        },
        {
          path: '/extension/crossWebsite/addKeyword',
          name: 'extension-crossWebsite-addKeyword',
          component: () => import('@/views/extension/crossWebsite/addKeyword.vue'),
          meta: {
            name: '关键词',
            rules: 0,
            form: 1,
            relation: 'extension-crossWebsite-keywords',
          },
        },
        {
          path: '/extension/crossWebsite/editKeyword',
          name: 'extension-crossWebsite-editKeyword',
          component: () => import('@/views/extension/crossWebsite/addKeyword.vue'),
          meta: {
            name: '关键词',
            rules: 0,
            form: 1,
            relation: 'extension-crossWebsite-keywords',
          },
        },
      ],
    },
    {
      path: '/extension/movableBulletBox',
      name: 'extension-movableBulletBox',
      component: () => import('@/views/extension/movableBulletBox'),
      meta: {
        name: '活动弹框',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/extension/PCOfficialWebsite',
      name: 'extension-PCOfficialWebsite',
      redirect: '/extension/PCOfficialWebsite/OfficialWebsiteDecoration',
      component: () => import('@/PCOfficialWebsite/index'),
      meta: {
        name: 'PC官网',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/PCOfficialWebsite/OfficialWebsiteDecoration',
          name: 'extension-PCOfficialWebsite-OfficialWebsiteDecoration',
          component: () => import('@/PCOfficialWebsite/OfficialWebsiteDecoration/index'),
          meta: {
            name: '官网装修',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/PCOfficialWebsite/BannerManagement',
          name: 'extension-PCOfficialWebsite-BannerManagement',
          component: () => import('@/PCOfficialWebsite/BannerManagement/index'),
          meta: {
            name: 'banner管理',
            rules: 1,
          },
        },
        {
          path: '/extension/PCOfficialWebsite/addBanner',
          name: 'extension-PCOfficialWebsite-addBanner',
          component: () => import('@/PCOfficialWebsite/BannerManagement/form'),
          meta: {
            name: '添加banner',
            rules: 0,
            relation: 'extension-PCOfficialWebsite-BannerManagement',
            form: 1,
          },
        },
        {
          path: '/extension/PCOfficialWebsite/editBanner',
          name: 'extension-PCOfficialWebsite-editBanner',
          component: () => import('@/PCOfficialWebsite/BannerManagement/form'),
          meta: {
            name: '编辑banner',
            rules: 0,
            relation: 'extension-PCOfficialWebsite-BannerManagement',
            form: 1,
          },
        },
        {
          path: '/extension/PCOfficialWebsite/FormCollection',
          name: 'extension-PCOfficialWebsite-FormCollection',
          component: () => import('@/PCOfficialWebsite/FormCollection/index'),
          meta: {
            name: '表单收集',
            rules: 1,
          },
        },
        {
          path: '/extension/PCOfficialWebsite/SubscriptionList',
          name: 'extension-PCOfficialWebsite-SubscriptionList',
          component: () => import('@/PCOfficialWebsite/SubscriptionList/index'),
          meta: {
            name: '订阅列表',
            rules: 1,
          },
        },
        {
          path: '/extension/PCOfficialWebsite/ServiceProviderManagement',
          name: 'extension-PCOfficialWebsite-ServiceProviderManagement',
          component: () => import('@/PCOfficialWebsite/ServiceProviderManagement/index'),
          meta: {
            name: '服务商管理',
            rules: 1,
          },
        },
        {
          path: '/extension/PCOfficialWebsite/TextManagement',
          name: 'extension-PCOfficialWebsite-TextManagement',
          component: () => import('@/PCOfficialWebsite/TextManagement/index'),
          meta: {
            name: '文本管理',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/PCOfficialWebsite/DocumentationManagement',
          name: 'extension-PCOfficialWebsite-DocumentationManagement',
          component: () => import('@/PCOfficialWebsite/TextManagement/documentation'),
          meta: {
            name: '文档管理',
            rules: 1,
          },
        },
        {
          path: '/extension/PCOfficialWebsite/NewsInformation',
          name: 'extension-PCOfficialWebsite-NewsInformation',
          component: () => import('@/PCOfficialWebsite/NewsInformation/index'),
          meta: {
            name: '资讯管理',
            rules: 1,
          },
        },
        {
          path: '/extension/PCOfficialWebsite/addNewsInformation',
          name: 'extension-PCOfficialWebsite-addNewsInformation',
          component: () => import('@/PCOfficialWebsite/NewsInformation/add'),
          meta: {
            name: '资讯管理',
            rules: 0,
            relation: 'extension-PCOfficialWebsite-NewsInformation',
          },
        },
        {
          path: '/extension/PCOfficialWebsite/editNewsInformation',
          name: 'extension-PCOfficialWebsite-editNewsInformation',
          component: () => import('@/PCOfficialWebsite/NewsInformation/add'),
          meta: {
            name: '资讯管理',
            rules: 0,
            relation: 'extension-PCOfficialWebsite-NewsInformation',
          },
        },
        {
          path: '/extension/PCOfficialWebsite/MemberManagement',
          name: 'extension-PCOfficialWebsite-MemberManagement',
          component: () => import('@/PCOfficialWebsite/MemberManagement/index'),
          meta: {
            name: '会员管理',
            rules: 1,
          },
        },
      ],
    },

    {
      path: '/extension/flashSale',
      name: 'extension-flashSale',
      component: () => import('@/views/extension/flashSale/index'),
      meta: {
        name: '限时抢购',
        rules: 1,
      },
    },
    {
      path: '/extension/flashSale/add',
      name: 'extension-flashSale-add',
      component: () => import('@/views/extension/flashSale/form'),
      meta: {
        name: '限时抢购',
        rules: 0,
        form: 1,
        relation: 'extension-flashSale',
      },
    },
    {
      path: '/extension/flashSale/edit',
      name: 'extension-flashSale-edit',
      component: () => import('@/views/extension/flashSale/form'),
      meta: {
        name: '限时抢购',
        rules: 0,
        form: 1,
        relation: 'extension-flashSale',
      },
    },
    {
      path: '/extension/flashSale/info',
      name: 'extension-flashSale-info',
      component: () => import('@/views/extension/flashSale/form'),
      meta: {
        name: '限时抢购',
        rules: 0,
        form: 1,
        relation: 'extension-flashSale',
      },
    },
    {
      path: '/extension/pointsMall',
      name: 'extension-pointsMall',
      redirect: '/extension/pointsMall/pointGoods',
      component: () => import('@/views/extension/pointsMall/index'),
      meta: {
        name: '积分商城',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/pointsMall/pointGoods',
          name: 'extension-pointsMall-pointGoods',
          component: () => import('@/views/extension/pointsMall/pointGoods/index'),
          meta: {
            name: '积分商品',
            rules: 1,
          },
        },
        {
          path: '/extension/pointsMall/addPointGoods',
          name: 'extension-pointsMall-addPointGoods',
          component: () => import('@/views/extension/pointsMall/pointGoods/add'),
          meta: {
            name: '添加商品',
            rules: 0,
            relation: 'extension-pointsMall-pointGoods',
            form: 1,
          },
        },
        {
          path: '/extension/pointsMall/editPointGoods',
          name: 'extension-pointsMall-editPointGoods',
          component: () => import('@/views/extension/pointsMall/pointGoods/add'),
          meta: {
            name: '编辑商品',
            rules: 0,
            relation: 'extension-pointsMall-pointGoods',
            form: 1,
          },
        },
        {
          path: '/extension/pointsMall/integralSetting',
          name: 'extension-pointsMall-integralSetting',
          component: () => import('@/views/extension/pointsMall/integralSetting/index'),
          meta: {
            name: '积分设置',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/pointsMall/pointsOrder',
          name: 'extension-pointsMall-pointsOrder',
          component: () => import('@/views/extension/pointsMall/pointsOrder/OrderList'),
          meta: {
            name: '积分订单',
            rules: 1,
          },
        },
        {
          path: '/extension/pointsMall/pointsOrderDetail',
          name: 'extension-pointsMall-pointsOrderDetail',
          component: () => import('@/views/extension/pointsMall/pointsOrder/orderDetail'),
          meta: {
            name: '积分订单',
            relation: 'extension-pointsMall-pointsOrder',
            rules: 0,
          },
        },
        {
          path: '/extension/pointsMall/websitePointsOrder',
          name: 'extension-pointsMall-websitePointsOrder',
          component: () => import('@/views/extension/pointsMall/pointsOrder/websitePointsOrder'),
          meta: {
            name: 'H5积分订单',
            rules: 1,
          },
        },
        {
          path: '/extension/pointsMall/websitePointsOrderInfo',
          name: 'extension-pointsMall-websitePointsOrderInfo',
          component: () => import('@/views/extension/pointsMall/pointsOrder/websitePointsOrderInfo'),
          meta: {
            name: '积分订单',
            relation: 'extension-pointsMall-websitePointsOrder',
            rules: 0,
          },
        },
      ],
    },
    {
      path: '/extension/Newcomer',
      name: 'extension-Newcomer',
      component: () => import('@/views/extension/Newcomer/index'),
      meta: {
        name: '优惠专区',
        rules: 1,
      },
    },
    {
      path: '/extension/Newcomer/add',
      name: 'extension-Newcomer-add',
      component: () => import('@/views/extension/Newcomer/form'),
      meta: {
        name: '优惠专区',
        rules: 0,
        form: 1,
        relation: 'extension-Newcomer',
      },
    },
    {
      path: '/extension/Newcomer/edit',
      name: 'extension-Newcomer-edit',
      component: () => import('@/views/extension/Newcomer/form'),
      meta: {
        name: '优惠专区',
        rules: 0,
        form: 1,
        relation: 'extension-Newcomer',
      },
    },
    {
      path: '/extension/Newcomer/info',
      name: 'extension-Newcomer-info',
      component: () => import('@/views/extension/Newcomer/form'),
      meta: {
        name: '优惠专区',
        rules: 0,
        form: 1,
        relation: 'extension-Newcomer',
      },
    },
    {
      path: '/extension/gatewayActivity',
      name: 'extension-gatewayActivity',
      redirect: '/extension/gatewayActivity/listActivity',
      component: () => import('@/gateway/activity/index'),
      meta: {
        name: '活动管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/gatewayActivity/listActivity',
          name: 'extension-gatewayActivity-listActivity',
          component: () => import('@/gateway/activity/listActivity'),
          meta: {
            name: '活动列表',
            rules: 1,
          },
        },
        {
          path: '/extension/gatewayActivity/addActivity',
          name: 'extension-gatewayActivity-addActivity',
          component: () => import('@/gateway/activity/addActivity'),
          meta: {
            name: '添加活动',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/gatewayActivity/order',
          name: 'extension-gatewayActivity-order',
          component: () => import('@/gateway/activity/order'),
          meta: {
            name: '活动订单',
            rules: 1,
          },
        },
        {
          path: '/extension/gatewayActivity/editActivity',
          name: 'extension-gatewayActivity-editActivity',
          component: () => import('@/gateway/activity/addActivity'),
          meta: {
            name: '编辑活动',
            rules: 0,
            form: 1,
            relation: 'extension-gatewayActivity-listActivity',
          },
        },
        {
          path: '/extension/gatewayActivity/lookActivity',
          name: 'extension-gatewayActivity-lookActivity',
          component: () => import('@/gateway/activity/addActivity'),
          meta: {
            name: '查看活动',
            rules: 0,
            form: 1,
            relation: 'extension-gatewayActivity-listActivity',
          },
        },
      ],
    },
    {
      path: '/extension/gateway/integralSetting',
      name: 'extension-gateway-IntegralSetting',
      component: () => import('@/gateway/IntegralSetting/IntegralSetting.vue'),
      meta: {
        name: '积分设置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/extension/cooperation',
      name: 'extension-cooperation',
      redirect: '/extension/cooperation/list',
      component: () => import('@/gateway/cooperation/index'),
      meta: {
        name: '合作管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/cooperation/list',
          name: 'extension-cooperation-list',
          component: () => import('@/gateway/cooperation/list'),
          meta: {
            name: '合作列表',
            rules: 1,
          },
        },
        {
          path: '/extension/cooperation/add',
          name: 'extension-cooperation-add',
          component: () => import('@/gateway/cooperation/add'),
          meta: {
            name: '添加合作',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/cooperation/edit',
          name: 'extension-cooperation-edit',
          component: () => import('@/gateway/cooperation/add'),
          meta: {
            name: '编辑合作',
            rules: 0,
            form: 1,
            relation: 'extension-cooperation-add',
          },
        },
        {
          path: '/extension/cooperation/examine',
          name: 'extension-cooperation-examine',
          component: () => import('@/gateway/cooperation/examine'),
          meta: {
            name: '合作申请',
            rules: 1,
          },
        },
        {
          path: '/extension/cooperation/setting',
          name: 'extension-cooperation-setting',
          component: () => import('@/gateway/cooperation/setting'),
          meta: {
            name: '合作设置',
            rules: 1,
            form: 1,
          },
        },
      ],
    },
    {
      path: '/extension/advertisement',
      name: 'extension-advertisement',
      redirect: '/extension/advertisement/list',
      component: () => import('@/gateway/advertisement/index'),
      meta: {
        name: '广告管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/advertisement/list',
          name: 'extension-advertisement-list',
          component: () => import('@/gateway/advertisement/list'),
          meta: {
            name: '广告列表',
            rules: 1,
          },
        },
        {
          path: '/extension/advertisement/add',
          name: 'extension-advertisement-add',
          component: () => import('@/gateway/advertisement/add'),
          meta: {
            name: '添加广告',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/advertisement/edit',
          name: 'extension-advertisement-edit',
          component: () => import('@/gateway/advertisement/add'),
          meta: {
            name: '编辑广告',
            rules: 0,
            form: 1,
            relation: 'extension-advertisement-list',
          },
        },
        {
          path: '/extension/advertisement/order',
          name: 'extension-advertisement-order',
          component: () => import('@/gateway/advertisement/order'),
          meta: {
            name: '广告订单',
            rules: 1,
          },
        },
        {
          path: '/extension/advertisement/set',
          name: 'extension-advertisement-set',
          component: () => import('@/gateway/advertisement/set'),
          meta: {
            name: '广告设置',
            rules: 1,
            form: 1,
          },
        },
      ],
    },
    {
      path: '/extension/keywords',
      name: 'extension-keywords',
      redirect: '/extension/keywords/list',
      component: () => import('@/gateway/hasChildrenRouter'),
      meta: {
        name: '关键词管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/keywords/list',
          name: 'extension-keywords-list',
          component: () => import('@/gateway/keywords/list'),
          meta: {
            name: '关键词列表',
            rules: 1,
          },
        },
        {
          path: '/extension/keywords/order',
          name: 'extension-keywords-order',
          component: () => import('@/gateway/keywords/order'),
          meta: {
            name: '关键词订单',
            rules: 1,
          },
        },
        {
          path: '/extension/keywords/set',
          name: 'extension-keywords-set',
          component: () => import('@/gateway/keywords/set'),
          meta: {
            name: '关键词设置',
            rules: 1,
            form: 1,
          },
        },
      ],
    },
    {
      path: '/extension/currentprice',
      name: 'extension-currentprice',
      redirect: '/extension/currentprice/list',
      component: () => import('@/gateway/hasChildrenRouter'),
      meta: {
        name: '时价管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/currentprice/list',
          name: 'extension-currentprice-list',
          component: () => import('@/gateway/currentprice/list'),
          meta: {
            name: '时价列表',
            rules: 1,
          },
        },
        {
          path: '/extension/currentprice/add',
          name: 'extension-currentprice-add',
          component: () => import('@/gateway/currentprice/form'),
          meta: {
            name: '添加时价',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/currentprice/edit',
          name: 'extension-currentprice-edit',
          component: () => import('@/gateway/currentprice/form'),
          meta: {
            name: '编辑时价',
            rules: 0,
            form: 1,
            relation: 'extension-currentprice-list',
          },
        },
        {
          path: '/extension/currentprice/audit',
          name: 'extension-currentprice-audit',
          component: () => import('@/gateway/currentprice/audit'),
          meta: {
            name: '时价审核',
            rules: 1,
          },
        },
        {
          path: '/extension/currentprice/order',
          name: 'extension-currentprice-order',
          component: () => import('@/gateway/currentprice/order'),
          meta: {
            name: '时价订单',
            rules: 1,
          },
        },
        {
          path: '/extension/currentprice/set',
          name: 'extension-currentprice-set',
          component: () => import('@/gateway/currentprice/set'),
          meta: {
            name: '时价设置',
            rules: 1,
          },
        },
      ],
    },
    //聚推客
    {
      path: '/extension/gatherTweeters',
      name: 'extension-gatherTweeters',
      redirect: '/extension/gatherTweeters/serviceProviderList',
      component: () => import('@/views/extension/gatherTweeters/index'),
      meta: {
        name: '聚推客',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/gatherTweeters/serviceProviderList',
          name: 'extension-gatherTweeters-serviceProviderList',
          component: () => import('@/views/extension/gatherTweeters/serviceProviderList'),
          meta: {
            name: '服务商列表',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/regimentList',
          name: 'extension-gatherTweeters-regimentList',
          component: () => import('@/views/extension/gatherTweeters/regimentList'),
          meta: {
            name: '团长列表',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/merchantList',
          name: 'extension-gatherTweeters-merchantList',
          component: () => import('@/views/extension/gatherTweeters/merchantList'),
          meta: {
            name: '商家列表',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/userConnectWifi',
          name: 'extension-gatherTweeters-userConnectWifi',
          component: () => import('@/views/extension/gatherTweeters/userConnectWifi'),
          meta: {
            name: '用户连接wifi列表',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/videoAudit',
          name: 'extension-gatherTweeters-videoAudit',
          component: () => import('@/views/extension/gatherTweeters/videoAudit'),
          meta: {
            name: '贴码视频审核',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/authorizationWx',
          name: 'extension-gatherTweeters-authorizationWx',
          component: () => import('@/views/extension/gatherTweeters/authorizationWx'),
          meta: {
            name: '授权小程序',
            rules: 0,
            relation: 'extension-gatherTweeters-merchantList',
          },
        },
        {
          path: '/extension/gatherTweeters/merchantReview',
          name: 'extension-gatherTweeters-merchantReview',
          component: () => import('@/views/extension/gatherTweeters/merchantReview'),
          meta: {
            name: '商家审核',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/withdrawalAudit',
          name: 'extension-gatherTweeters-withdrawalAudit',
          component: () => import('@/views/extension/gatherTweeters/withdrawalAudit'),
          meta: {
            name: '提现审核',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/userWithdrawalAudit',
          name: 'extension-gatherTweeters-userWithdrawalAudit',
          component: () => import('@/views/extension/gatherTweeters/userWithdrawalAudit'),
          meta: {
            name: '用户提现审核',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/basicSettingTest',
          name: 'extension-gatherTweeters-basicSettingTest',
          component: () => import('@/views/extension/gatherTweeters/basicSettingTest'),
          meta: {
            name: '基础设置',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/revenueOrders',
          name: 'extension-gatherTweeters-revenueOrders',
          component: () => import('@/views/extension/gatherTweeters/revenueOrders'),
          meta: {
            name: '收益订单',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/dataBoard',
          name: 'extension-gatherTweeters-dataBoard',
          component: () => import('@/views/extension/gatherTweeters/dataBoard'),
          meta: {
            name: '数据看板',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/operatorIncome',
          name: 'extension-gatherTweeters-operatorIncome',
          component: () => import('@/views/extension/gatherTweeters/operatorIncome'),
          meta: {
            name: '运营商收益统计',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/operatorMerchants',
          name: 'extension-gatherTweeters-operatorMerchants',
          component: () => import('@/views/extension/gatherTweeters/operatorMerchants'),
          meta: {
            name: '运营商商家统计',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/downCenter',
          name: 'extension-gatherTweeters-downCenter',
          component: () => import('@/views/extension/gatherTweeters/downCenter'),
          meta: {
            name: '导出文件',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/revenueOrdersAll',
          name: 'extension-gatherTweeters-revenueOrdersAll',
          component: () => import('@/views/extension/gatherTweeters/revenueOrdersAll'),
          meta: {
            name: '全部收益订单',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/trilateralOrder',
          name: 'extension-gatherTweeters-trilateralOrder',
          component: () => import('@/views/extension/gatherTweeters/trilateralOrder'),
          meta: {
            name: '第三方订单',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/rAndeDetails',
          name: 'extension-gatherTweeters-rAndeDetails',
          component: () => import('@/views/extension/gatherTweeters/rAndeDetails'),
          meta: {
            name: '收支明细',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/staffList',
          name: 'extension-gatherTweeters-staffList',
          component: () => import('@/views/extension/gatherTweeters/staffList'),
          meta: {
            name: '员工列表',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/sourceMaterial',
          name: 'extension-gatherTweeters-sourceMaterial',
          component: () => import('@/views/extension/gatherTweeters/sourceMaterial'),
          meta: {
            name: '发圈素材',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/container',
          name: 'extension-gatherTweeters-container',
          component: () => import('@/views/extension/gatherTweeters/container'),
          meta: {
            name: '货柜申请',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/payAudit',
          name: 'extension-gatherTweeters-payAudit',
          component: () => import('@/views/extension/gatherTweeters/payAudit'),
          meta: {
            name: '支付审核',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/poster',
          name: 'extension-gatherTweeters-poster',
          component: () => import('@/views/extension/gatherTweeters/poster'),
          meta: {
            name: '我的海报',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/luckyDraw',
          name: 'extension-gatherTweeters-luckyDraw',
          component: () => import('@/views/extension/gatherTweeters/luckyDraw'),
          meta: {
            name: '抽奖活动',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/luckyDrawSet',
          name: 'extension-gatherTweeters-luckyDrawSet',
          component: () => import('@/views/extension/gatherTweeters/luckyDrawSet'),
          meta: {
            name: '抽奖设置',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/luckyDrawData',
          name: 'extension-gatherTweeters-luckyDrawData',
          component: () => import('@/views/extension/gatherTweeters/luckyDrawData'),
          meta: {
            name: '活动记录',
            rules: 0,
            relation: 'extension-gatherTweeters-luckyDraw',
          },
        },
        {
          path: '/extension/gatherTweeters/addLuckyDraw',
          name: 'extension-gatherTweeters-addLuckyDraw',
          component: () => import('@/views/extension/gatherTweeters/addLuckyDraw'),
          meta: {
            name: '添加活动',
            rules: 0,
            form: 1,
            relation: 'extension-gatherTweeters-luckyDraw',
          },
        },
        {
          path: '/extension/gatherTweeters/editLuckyDraw',
          name: 'extension-gatherTweeters-editLuckyDraw',
          component: () => import('@/views/extension/gatherTweeters/addLuckyDraw'),
          meta: {
            name: '编辑活动',
            rules: 0,
            form: 1,
            relation: 'extension-gatherTweeters-luckyDraw',
          },
        },
        {
          path: '/extension/gatherTweeters/businessCate',
          name: 'extension-gatherTweeters-businessCate',
          component: () => import('@/views/extension/gatherTweeters/businessCate'),
          meta: {
            name: '经营分类',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/enterpriseSettlement',
          name: 'extension-gatherTweeters-enterpriseSettlement',
          component: () => import('@/views/extension/gatherTweeters/enterpriseSettlement'),
          meta: {
            name: '企业入驻',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/addEnterprise',
          name: 'extension-gatherTweeters-addEnterprise',
          component: () => import('@/views/extension/gatherTweeters/addEnterprise'),
          meta: {
            name: '企业入驻',
            rules: 0,
            form: 1,
            relation: 'extension-gatherTweeters-enterpriseSettlement',
          },
        },
        {
          path: '/extension/gatherTweeters/caseGoodsList',
          name: 'extension-gatherTweeters-caseGoodsList',
          component: () => import('@/views/extension/gatherTweeters/caseGoodsList'),
          meta: {
            name: '商品列表',
            rules: 0,
            form: 1,
            relation: 'extension-gatherTweeters-enterpriseSettlement',
          },
        },
        {
          path: '/extension/gatherTweeters/addCaseGoods',
          name: 'extension-gatherTweeters-addCaseGoods',
          component: () => import('@/views/extension/gatherTweeters/addCaseGoods'),
          meta: {
            name: '添加商品',
            rules: 0,
            form: 1,
            relation: 'extension-gatherTweeters-enterpriseSettlement',
          },
        },
        {
          path: '/extension/gatherTweeters/editCaseGoods',
          name: 'extension-gatherTweeters-editCaseGoods',
          component: () => import('@/views/extension/gatherTweeters/addCaseGoods'),
          meta: {
            name: '编辑商品',
            rules: 0,
            form: 1,
            relation: 'extension-gatherTweeters-enterpriseSettlement',
          },
        },
        {
          path: '/extension/gatherTweeters/JDHome',
          name: 'extension-gatherTweeters-JDHome',
          component: () => import('@/views/extension/gatherTweeters/JDHome'),
          meta: {
            name: '京东到家',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/douweiMarketing',
          name: 'extension-gatherTweeters-douweiMarketing',
          component: () => import('@/views/extension/gatherTweeters/douweiMarketing'),
          meta: {
            name: '抖微营销',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/MeituanFlashPurchase',
          name: 'extension-gatherTweeters-MeituanFlashPurchase',
          component: () => import('@/views/extension/gatherTweeters/MeituanFlashPurchase'),
          meta: {
            name: '美团闪购',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/thirdPartyData',
          name: 'extension-gatherTweeters-thirdPartyData',
          component: () => import('@/views/extension/gatherTweeters/thirdPartyData'),
          meta: {
            name: '第三方数据',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/enterpriseOrderList',
          name: 'extension-gatherTweeters-enterpriseOrderList',
          component: () => import('@/views/extension/gatherTweeters/enterpriseOrderList'),
          meta: {
            name: '企业订单',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/enterpriseOrderInfo',
          name: 'extension-gatherTweeters-enterpriseOrderInfo',
          component: () => import('@/views/extension/gatherTweeters/enterpriseOrderInfo'),
          meta: {
            name: '订单详情',
            rules: 0,
            relation: 'extension-gatherTweeters-enterpriseOrderList',
          },
        },
        //{
        //  path: '/extension/gatherTweeters/whiteList',
        //  name: 'extension-gatherTweeters-whiteList',
        //  component: () => import('@/views/extension/gatherTweeters/whiteList'),
        //  meta: {
        //    name: '白名单',
        //    rules: 1,
        //  },
        //},
        {
          path: '/extension/gatherTweeters/minifood',
          name: 'extension-gatherTweeters-minifood',
          component: () => import('@/views/extension/gatherTweeters/minidata.vue'),
          meta: {
            name: '餐饮版小程序',
            rules: 1,
          },
        },
        {
          path: '/extension/gatherTweeters/miniCustom',
          name: 'extension-gatherTweeters-miniCustom',
          component: () => import('@/views/extension/gatherTweeters/miniCustom.vue'),
          meta: {
            name: '自定义版小程序',
            rules: 1,
          },
        },
      ],
    },
    {
      path: '/extension/forum_Wxshop',
      name: 'extension-forum_Wxshop',
      redirect: '/extension/forum_Wxshop/list',
      component: () => import('@/views/extension/forum_Wxshop/Index'),
      meta: {
        name: '论坛管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/forum_Wxshop/list',
          name: 'extension-forum_Wxshop-list',
          component: () => import('@/views/extension/forum_Wxshop/List'),
          meta: {
            name: '论坛列表',
            rules: 1,
            selected: 0,
          },
        },
        {
          path: '/extension/forum_Wxshop/add',
          name: 'extension-forum_Wxshop-add',
          component: () => import('@/views/extension/forum_Wxshop/Add'),
          meta: {
            name: '发布论坛',
            rules: 1,
            form: 1,
          },
        },
        {
          path: '/extension/forum_Wxshop/detail',
          name: 'extension-forum_Wxshop-detail',
          component: () => import('@/views/extension/forum_Wxshop/Add'),
          meta: {
            name: '查看论坛',
            relation: 'extension-forum_Wxshop-list',
            rules: 0,
            form: 1,
          },
        },
        {
          path: '/extension/forum_Wxshop/type',
          name: 'extension-forum_Wxshop-type',
          component: () => import('@/views/extension/forum_Wxshop/Type'),
          meta: {
            name: '论坛分类',
            rules: 1,
          },
        },
        {
          path: '/extension/forum_Wxshop/setting',
          name: 'extension-forum_Wxshop-setting',
          component: () => import('@/views/extension/forum_Wxshop/Setting'),
          meta: {
            name: '论坛设置',
            rules: 1,
          },
        },
      ],
    },
    // 塑料批发管理
    {
      path: '/extension/gatewayWholesale',
      name: 'extension-gatewayWholesale',
      redirect: '/extension/gatewayWholesale/list',
      component: () => import('@/gateway/wholesale/index'),
      meta: {
        name: '批发管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/gatewayWholesale/list',
          name: 'extension-gatewayWholesale-list',
          component: () => import('@/gateway/wholesale/list'),
          meta: {
            name: '批发列表',
            rules: 1,
          },
        },
        {
          path: '/extension/gatewayWholesale/add',
          name: 'extension-gatewayWholesale-add',
          component: () => import('@/gateway/wholesale/add'),
          meta: {
            name: '添加批发',
            rules: 1,
          },
        },
        {
          path: '/extension/gatewayWholesale/edit',
          name: 'extension-gatewayWholesale-edit',
          component: () => import('@/gateway/wholesale/add'),
          meta: {
            name: '编辑批发',
            relation: 'extension-gatewayWholesale-list',
            rules: 0,
          },
        },
      ],
    },
    {
      path: '/extension/proxy',
      name: 'extension-proxy',
      redirect: '/extension/proxy/list',
      component: () => import('@/gateway/hasChildrenRouter'),
      meta: {
        name: '代销管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/extension/proxy/list',
          name: 'extension-proxy-list',
          component: () => import('@/gateway/proxy/list'),
          meta: {
            name: '代销认证',
            rules: 1,
          },
        },
        {
          path: '/extension/proxy/set',
          name: 'extension-proxy-set',
          component: () => import('@/gateway/proxy/set'),
          meta: {
            name: '代销设置',
            rules: 1,
            form: 1,
          },
        },
      ],
    },
  ],
};
