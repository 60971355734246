const api = {
  user: {
    registerUser: '/Users/registerUser', //注册账号
    systemSendSms: '/Users/systemSendSms',
    login: '/users/login',
    codeLogin: '/users/codeLogin',
    logout: '/users/logout',
    editPassword: '/Users/editPassword',
    userInfo: '/users/self',
    verifyCode: '/verifyCode/verifyCode',
    checkVerifyCode: '/verifyCode/checkVerifyCode',
    getAccountList: '/users/getAccountList',
    users_getAll: '/users/getAll',
    roles_getAll: '/roles/getAll',
    roles_delete: '/roles/delete',
    users_add: '/users/add', //添加员工
    users_update: '/Users/userUpdate', //修改员工
    users_del: '/users/del', //删除员工
    addProgram: '/Account/addProgram',
    getCase: '/Account/getCase',
    getMeal: '/Account/getMeal',
    innerProgram: '/Account/innerProgram',
    forgetPwd: '/users/forgetPwd',
    addFiles: '/Upload/addFiles',
    JwtImagesList: '/JwtImages/list',
    SystemUpload: '/SystemUpload/addFiles',
    update: '/Users/update',
    imgList: '/image/list',
    orderFind: '/account/orderFind',
    backProgram: '/Account/backProgram', //返回站点
    addCard: '/CardSet/add', //添加会员卡
    getCardInfo: '/CardSet/getOne', //获取会员卡信息
    editCard: '/CardSet/edit', //编辑会员卡
    CardLevel: '/CardLevel/index', // 会员等级
    CardLeveledit: '/CardLevel/edit', //修改会员等级
    CardLeveladd: '/CardLevel/add', // 添加会员等级
    CardLeveldel: '/CardLevel/delete', // 删除会员卡
    getGrowLogList: '/api/ShopCard/getGrowLogList', //成长值列表
    userList: '/UserOpen/index', //用户列表
    integralLog: '/IntegralLog/list', //积分
    setblack: '/UserOpen/setblack', //冻结
    setCredits: '/UserOpen/setCredits', //充值余额
    sendCoupons: '/UserOpen/sendCoupons', //赠送优惠券
    getCouponList: '/UserOpen/getCouponList', //获取优惠券列表
    getPortrait: '/UserPortrait/getData', //用户画像
    userDetail: '/UserOpen/detail', //用户详情
    myCoupons: '/UserOpen/getMyCouponsList', //我的优惠券
    growLog: '/UserOpen/growLog', //成长日志
    balance: '/UserOpen/balance', //余额明细
    log: '/UserOpenData/getList', //用户日志
    shopInfo: '/Shop/info', //店铺信息
    wxwork: {
      getWxwork: '/WorkWx/getWxwork',
      updateWxwork: '/WorkWx/updateWxwork',
      groupchatList: '/WorkWx/newGroupchatList',
      groupchatDetail: '/WorkWx/groupchatDetail',
      get_user_behavior_data: '/WorkWx/get_user_behavior_data',
      userList: '/WxExternalContact/getList',
      set_welcome_msg: '/WorkWx/set_welcome_msg',
      get_welcome_msg: '/WorkWx/get_welcome_msg',
      staffList: '/WxworkUser/list', //员工列表
      staff_member: '/WxworkUser/getExternalUser', //员工客户列表
      getWxworkMember: '/WxworkExternalContactMessage/getUser',
      sendMessage: '/WxworkExternalContactMessage/send',
      sendMessageLog: '/WxworkExternalContactMessage/list',
      sendMessageResult: '/WxworkExternalContactMessage/getSendStatus',
      setIsClient: '/WxworkUser/setIsClient',
      setWebView: '/WxCode/setWebView', // 配置业务域名
    },
    WxworkMessageList: '/WxworkMessage/list', // 群发列表接口
    departmentList: '/WxworkMessage/departmentList', // 部门成员列表
    addMessage: '/WxworkMessage/addMessage', // 通知成员发送接口
    getUserSend: '/WxworkMessage/getUserSend', // 群发统计接口
    getUserSendInfo: '/WxworkMessage/getUserSendInfo', // 群发统计-详情接口
    tagList: '/WxworkMessage/tagList', // 获取标签列表
    customerSelect: '/WxworkMessage/customerSelect', // 获取按条件筛选的客户
    get_product_album_list: '/WorkWx/get_product_album_list', // 获取商品图册列表
    add_product_album: '/WorkWx/add_product_album', // 获取商品
    update_product_album: '/WorkWx/update_product_album', // 编辑商品图册
    delete_product_album: '/WorkWx/delete_product_album', ///删除企微商品
  },
  member: {
    addGroup: '/UserOpenCluster/add',
    groupList: '/UserOpenCluster/list',
    groupInfo: '/UserOpenCluster/info',
    groupEdit: '/UserOpenCluster/edit',
    groupDel: '/UserOpenCluster/delete',
    getGroupNum: '/UserOpenCluster/getClusterNum',
    getRecommend: '/UserOpenCluster/getRecommend',
    editUser: '/UserOpen/edit',
  },
  //权限
  auths: {
    add: '/auths/add', //添加菜单权限
    getAll: '/auths/getAll', //获取菜单列表
    update: '/auths/update', //编辑菜单权限
    getOne: '/auths/getOne', //获取菜单详情
    get_menu: '/auths/get_menu', //获取对应账号的菜单
    get_tree_list: '/auths/get_tree_list', //获取总菜单树
    addRoles: '/Roles/add', //添加角色
    editRoles: '/Roles/update',
  },
  goods: {
    applyAndGoods: '/En/Goods/list', // 商品列表接口
    goodsClass: '/GoodsClassify/list',
    addGoods: '/Goods/add',
    addType: '/GoodsClassify/add',
    list: '/Goods/list',
    editInfo: '/Goods/editInfo', //编辑商品信息
    delType: '/GoodsClassify/del',
    delGoods: '/Goods/del',
    editGoods: '/Goods/edit',
    editType: '/GoodsClassify/edit',
    goodsInfo: '/Goods/info',
    shiftGoods: '/Goods/shiftGoods',
    commentList: '/GoodsComment/list', //评价列表
    reply: '/GoodsComment/reply', //回复
    setStatus: '/GoodsComment/setStatus', //评价显示隐藏
    delComment: '/GoodsComment/del', //删除评价
    setPutaway: '/Goods/setPutaway', //设置上架\下架
    goodsStatus: '/Goods/goodsStatus', //商品状态
    isSelect: '/Goods/isSelect', //是否有默认运费模块
    export: '/Goods/export', //导出商品
    import: '/Goods/import', //导入商品
    copyGoods: '/Goods/copyGoods', //复制商品
    exportGoodsClass: '/GoodsClassify/export', //导出商品分类
    wxCode: '/Goods/wxCode',
    setFreight: '/Goods/setFreight',
    setClassify: '/Goods/setClassify',
    groupGoodsList: '/Group/groupGoodsList',
    customForm: '/CustomForm/list',
    getTemplate: '/Goods/getTemplate', //获取导入模版
  },
  order: {
    setExpires: '/Shop/setOrderExpires', //设置订单过期时间
    list: '/order/list', //获取订单列表
    info: '/order/info', //获取订单详情
    setSend: '/order/setSend', //订单发货接口
    num: '/order/stateNum', //订单数量
    express: '/express/list', //物流信息
    logisticsQuery: '/Order/logisticsQuery', //物流信息查询
    setSystemMessage: '/Order/setSystemMessage', //添加系统备注信息
    changePrice: '/Order/changePrice', //修改订单价格
    cancel: '/Order/cancel', //取消未付款订单
    cancelList: '/Orderrefundreason/list', //退款原因
    printOrder: '/Order/printOrder', //打印订单
    handleRefund: '/Orderrefund/handle', //处理退款
    salesList: '/Orderrefund/list', //售后列表
    refundInfo: '/Orderrefund/info', //退款详情
    receive: '/Orderrefund/receive', //商家收货
    refund: '/Orderrefund/refund', //原路退回到钱包
    confirmReceipt: '/order/confirmReceipt', //订单确认收货
    refundNum: '/Orderrefund/stateNum', //退款订单数量
    editTracking: '/Order/editTracking', //编辑物流
    douOrderList: '/ServiceOrder/douOrderList', //获取抖音需求订单列表
    autoInvoiceShare: '/Orderrefund/autoInvoiceShare', //售后驳回分佣开票
    logisticsAudit: '/Orderrefund/logisticsAudit', //运营审核售后
  },
  site: {
    accountList: '/users/getAccountList',
    innerProgram: '/Account/innerProgram',
    getCase: '/Account/getCase', //站点方案
    getMeal: '/Account/getMeal', //站点套餐
    addProgram: '/Account/addProgram', //添加站点
    updateAccountInfo: '/account/updateAccountInfo', //修改站点
    delAccount: '/account/delAccount', //删除站点
    getPreOrder: '/account/getPreOrder', //订单预览
    renewPay: '/account/renewPay',
    ableCase: '/Account/getAbleCase',
  },
  freight: {
    list: '/Freight/list', //运费列表
  },
  store: {
    storeList: '/Store/list', //门店列表
    addStore: '/Store/add', //添加门店
    editStore: '/Store/edit', //编辑门店
    delStore: '/Store/del', //删除门店
    editAccount: '/Store/editAccount', //对账管理
    goodsList: '/Store/goodsList', //商品管理列表
    storeUsersList: '/StoreUsers/list', //店员列表
    addStoreUsers: '/StoreUsers/add', //添加店员
    editStoreUsers: '/StoreUsers/edit', //编辑店员
    delStoreUsers: '/StoreUsers/del', //删除店员
    goodsAuditList: '/Store/storeGoodsAuditList', //商品审核列表
    goodsAudit: '/Store/storeGoodsAudit', //商品审核
    goodsAuditLog: '/Store/storeGoodsAuditLog', //商品审核详情
    withdrawLog: '/Store/withdrawLog',
    getStatus: '/Store/getStatus',
    withdrawLogInfo: '/Store/withdrawLogInfo',
    withdrawnList: '/Store/withdrawnList',
    upStatus: '/Store/upStatus',
    correlationGoods: '/Store/correlationGoods',
    StoreUsersedit: '/StoreUsers/edit',
    StoreUsersinfo: '/StoreUsers/info',
    checkInStatus: '/StoreSettled/settledStatus',
    StoreSettled: '/StoreSettled/list',
    SettledupStatus: '/StoreSettled/upStatus',
    StoreExport: '/WxShop/Store/export',
    withdrawLogExport: '/Store/withdrawLogExport',
    storeErCode: '/Store/wxCode',
    getStatusCompany: '/WxShop/CompanyWithdraw/getStatus',
    withdrawLogCompany: '/WxShop/CompanyWithdraw/list',
    withdrawnListCompany: '/WxShop/CompanyWithdraw/orderGoodsList',
    withdrawnAuditCompany: '/WxShop/CompanyWithdraw/audit',
    earningsStatisticsInfo: '/WxShop/Facilitator/earningsStatisticsInfo',
    DouWechatList: '/WxShop/DouWechat/list',
    DouWechatUpStatus: '/WxShop/DouWechat/upStatus',
  },
  set: {
    editVideo: '/Upload/editVideo', // 添加视频信息
    freightAdd: '/Freight/add', //添加运费
    freightList: '/Freight/list', //运费列表
    freightDel: '/Freight/del', //删除运费
    freightEdit: '/Freight/edit', //编辑运费
    wxCode: '/WxCode/getPreAuthorizationUrl', //获取微信授权
    wxShopSetting: '/ShopSetting/add', //微信支付配置
    wxAuditStatus: '/WxCode/getAuditStatus', //微信审核状态
    submitAudit: '/WxCode/submitAudit', //提交审核
    wxundocodeaudit: '/WxCode/undocodeaudit', //微信小程序审核撤回
    rejectList: '/WxCode/getThirdLog', // 获取驳回原因列表
    wxtesterBind: '/WxCode/testerBind', //wx绑定体验者
    wxtesterUnbind: '/WxCode/testerUnbind', //wx解绑体验者
    testerList: '/WxCode/testerList', // 体验者列表
    wxRelease: '/WxCode/release', //wx小程序发布
    wxCommit: '/WxCode/commit', //wx提交审核
    wxqrCode: '/WxCode/qrCode', //wx获取体验码
    wxApp: '/WxCode/getWxapp', // wx设置
    setWxapp: '/WxCode/setWxapp', // 设置微信设置
    Wxticket: '/api/Wxticket/handleAuthorize', //微信授权
    // 微信公众号设置
    officialAccountSet: {
      getPreAuthorizationUrl: '/Retail/Wechat/getPreAuthorizationUrl', // 获取授权链接
      handleAuthorize: '/Retail/Wechat/handleAuthorize', // 回调设置站点信息
      getWechat: '/Retail/Wechat/getWechat', // 获取公众号设置信息
      getCategory: '/Retail/Wechat/getCategory', //获取公众号类别
      addTemplate: '/Retail/Wechat/addTemplate', // 设置模板
      getMenucz: '/Retail/Wechat/getMenucz', // 已经授权后获取设置的菜单
      getLinkMiniprogram: '/Retail/Wechat/getLinkMiniprogram',
      createMenu: '/Retail/Wechat/createMenu', // 添加菜单
    },
    addArticleClass: '/ArticleClassify/add', //添加文章分类
    listArticleClass: '/ArticleClassify/list', //文章分类列表
    editArticleClass: '/ArticleClassify/edit', //编辑文章分类
    delArticleClass: '/ArticleClassify/del', //删除文章分类
    addArticle: '/Article/add', //添加文章
    editArticle: '/Article/edit', //编辑文章
    delArticle: '/Article/del', //删除文章
    listArticle: '/Article/list', //文章列表
    rejectArticle: '/Article/editInfo', //文章驳回
    address: '/Shipper/list', //地址
    addAddress: '/shipper/add', //添加地址
    editAddress: '/shipper/edit', //编辑地址
    delAddress: '//shipper/del', //删除地址
    setDefaultAddress: '/shipper/setDefault', //设置默认地址
    setDefaultFreight: '/Freight/setDefault', //设置默认运费
    Settinginfo: '/ShopSetting/info', // 获取设置信息
    Settingedit: '/ShopSetting/edit', // 修改设置
    Settingadd: '/ShopSetting/add', // 添加设置
    area: '/area/list', //省市区
    ShopSms: '/ShopSms/getData', // 获取短信数
    ShopSmsOrder: '/ShopSms/recharge', // 短信充值订单
    addTemplate: '/WxTemplate/addTemplate', // 一键设置
    getCategory: '/WxTemplate/getCategory',
    targetTurnover: '/TargetTurnover/list',
    authoAli: '/AliCode/getPreAuthorizationUrl', //'支付宝授权'
    isAuth: '/AliCode/isAuth', //判断是否授权
    aliCommit: '/AliCode/commit', //支付宝上传小程序
    aliSubmitAudit: '/AliCode/submitAudit', //支付宝提交审核
    versionListQuery: '/AliCode/versionListQuery', // 获取所有版本列表
    experienceCreate: '/AliCode/experienceCreate', // 创建体验版
    bindMember: '/AliCode/bindMember', //添加体验者（开发者）
    experienceQuery: '/AliCode/experienceQuery', //查看体验版二维码
    versionOnline: '/AliCode/versionOnline', // 上架小程序
    versionDetailQuery: '/AliCode/versionDetailQuery', //查询审核详情
    membersDelete: '/AliCode/membersDelete', // 支付宝删除体验者
    membersQuery: '/AliCode/membersQuery', //获取体验者列表
    auditCancel: '/AliCode/auditCancel', // 撤销审核
    DouyinInfo: '/DouyinCode/isAuth', // 获取抖音小程序授权信息
    addCustomer: '/CustomerService/addCustomer', // 添加客服坐席
    serviceList: '/CustomerService/list', //获取客服列表
    storeList: '/Store/storeList', // 获取所有门店列表
    editCustomer: '/CustomerService/editCustomer', // 编辑客服
    delCustomer: '/CustomerService/delCustomer', // 删除客服
    // 微信客服
    wechatCustomer: {
      list: '/WxCode/getkflists',
      add: '/WxCode/addkf',
      del: '/WxCode/delkf',
    },
    baidu: {
      getAuthorizationUrl: '/BdCode/getAuthorizationUrl', // 获取百度预授权链接
      isAuth: '/BdCode/isAuth', //判断是否授权
      getAccessToken: '/BdCode/getAccessToken2', // 写入小程序code
      upload: '/BdCode/upload', // 上传代码
      submitAudit: '/BdCode/submitAudit', // 提交审核
      withdraw: '/BdCode/withdraw', // 撤销审核
      release: '/BdCode/release', // 发布小程序
      getQrcode: '/BdCode/getQrcode', // 获取体验码
    },
    QQ: {
      isAuth: '/QqCode/isAuth', // 判断是否授权
    },
    buyGorH: {
      getIsPay: '/Product/getIsPay', // 查看产品是否购买 --> 一次性买断
      Productlist: '/Product/list', // 产品类型列表
      createOrder: '/ProductOrder/createOrder', // 创建订单
      getOrderStatus: '/ProductOrder/getOrderStatus', // 查询订单状态
    },
    fastApp: {
      isAuth: '/QuickappCode/isAuth', // 判断站点是否授权
    },
    BaiduCloudDisk: {
      getAuthorizeUrl: '/BdCloud/getAuthorizeUrl', // 获取授权链接
      handleAuthorize: '/api/BdCloud/handleAuthorize', // 回调页
      listAll: '/BdCloud/listAll', // 查询已绑定的所有的百度云账号
      delete: '/BdCloud/delete', // 删除已绑定的百度云账号
      updateStatus: '/BdCloud/updateStatus', // 更新云盘数据
    },
    videoNumber: {
      getShareInfo: '/Share/info', //获取分享群配置接口
      editShareInfo: '/Share/edit', // 编辑分享群设置接口
      checkInfo: '/WxShop/getWxShop', // 检测信息是否配置完整
      registerCheck: '/WxShop/registerCheck', // 申请开通自定义组件
      getSystemGoods: '/WxShop/Goods/getSystemGoods', // 获取系统自动新增的商品
      addDefaultProduct: '/WxShop/Goods/addDefaultProduct', //交易组件立即同步
      getQrcode: '/WxShop/getQrcode', // 审核通过后商品的二维码
      addWxShopGoods: '/WxShop/Goods/addWxShopGoods', //添加视频号商品
      getWxShopCat: '/WxShop/getWxShopCat',
      set_commission: '/WxShop/Goods/set_commission',
      getWxShopGoods: '/WxShop/Goods/getWxShopGoods',
      set_commission: '/WxShop/Goods/set_commission',
      updateSpu: '/WxShop/Goods/updateSpu',
      delGoods: '/WxShop/Goods/delSpu',
      getCatLogList: '/WxShop/getCatLogList', // 获取分类审核列表
      auditCategory: '/WxShop/auditCategory', // 上传类目资质
      imgUpload: '/WxShop/imgUpload', // 上传图片
    },
    storeSettled: '/WxShop/StoreSettled/list', //商家审核列表
    storeSettledUpStatus: '/WxShop/StoreSettled/upStatus', //商家审核
    storeList: '/WxShop/Store/list', //商家列表
    TwitterSet: '/WxShop/TwitterSet/set', // wifi小程序设置基础设置
    TwitterSetInfo: '/WxShop/TwitterSet/info', // 获取wifi小程序设置基础设置
    statisticalData: '/WxShop/StatisticalData/getData', // 获取wifi小程序设置基础设置
    statisticalDataData: '/WxShop/StatisticalData/getDateData', // 获取wifi小程序设置基础设置
    earningsStatistics: '/WxShop/Facilitator/earningsStatistics', // 运营商收益
    earningsStatisticsExport: '/WxShop/Facilitator/earningsStatisticsExport', // 运营商收益导出文件
    ExportFile: '/ExportFile/list', // 导出文件设置
    storeStatistics: '/WxShop/Facilitator/storeStatistics', // 运营商商家
    storeStatisticsExport: '/WxShop/Facilitator/storeStatisticsExport', // 运营商商家导出文件
    ExportFileDel: '/ExportFile/del', // 删除导出文件

    sendSetinfo: '/StoreSendSet/info',
    sendSetEdit: '/StoreSendSet/edit',

    AdminOperationLog: '/AdminOperationLog/list',
  },
  //推广
  push: {
    addCoupon: '/Coupon/add', //添加优惠券
    couponList: '/Coupon/list', //优惠券列表
    couponEdit: '/Coupon/edit', //编辑优惠券
    couponEel: '/Coupon/del', //删除优惠券
    editStatus: '/Coupon/editStatus', //编辑优惠券状态
    addEnclosure: '/ElectronicFence/add', //添加围栏
    enclosureList: '/ElectronicFence/list', //围栏列表
    editEnclosure: '/ElectronicFence/edit', //编辑围栏
    delEnclosure: '/ElectronicFence/del', //删除围栏
    upStatusEnclosure: '/ElectronicFence/upStatus', //是否启用电子围栏
    getTradingArea: '/ElectronicFence/getTradingArea', //获取商圈
    editPush: '/ElectronicFence/editPush', //添加围栏推送
    pushList: '/ElectronicFence/pushList', //围栏推送列表
    pushInfo: '/ElectronicFence/pushInfo', //推送详情
    liveRoom: {
      liveBookList: '/LiveBook/index', // 获取直播间列表
      getAppData: '/LiveBook/getData', // 获取绑定的小程序信息
      liveImgUp: '/LiveBook/upload', //直播图片素材上传
      liveRoomAdd: '/LiveBook/add', // 添加直播间
      getSharedCode: '/LiveBook/getSharedCode', // 获取直播间分享信息
      goodsList: '/LiveAllGoods/index', // 获取商品列表
      getGoodList: '/LiveAllGoods/getGoodList', // 全站的商品列表
      addAllGoods: '/LiveAllGoods/addAllGoods', // 添加商品到商品库并提审
      resubmitAudit: '/LiveAllGoods/resubmitAudit', // 重新提审商品
      resetAudit: '/LiveAllGoods/resetAudit', //撤回审核
      getInfo: '/LiveBook/getInfo', //根据room_id查询直播间信息
      liveRoomEdit: '/LiveBook/edit', // 修改直播间信息
      addGoods: '/LiveBook/addGoods', // 将商品绑定到直播间
      getLiveGoodList: '/LiveBook/getGoodList', //获取直播间已经绑定的商品列表
      deleteInRoom: '/LiveBook/deleteInRoom', // 在此直播间中下架该商品
      deleteLiveRoom: '/LiveBook/deleteLiveRoom', // 删除直播间
      updateGoods: '/LiveAllGoods/updateGoods', // 修改商品价格
      getGoodsData: '/LiveBook/getGoodsData', // 获取直播间的直播的详细信息
      deleteGoods: '/LiveAllGoods/deleteGoods', // 删除商品库中的商品
    },
    wholesale: {
      list: '/SameCityGoodsBatch/getIndex', // 批发列表
      SameCityGoodsBatch: '/SameCityGoodsBatch/getIndex',
      delete: '/SameCityGoodsBatch/delete', // 列表删除商品
      wholesaleAdd: '/SameCityGoodsBatch/add', // 添加批发商品
      edit: '/SameCityGoodsBatch/edit', // 修改商品批发价格
      sort: '/SameCityGoodsBatch/sort', // 修改排序
      addWholesale: '/Gateway/Wholesale/add', // 塑料添加批发商品
      editWholesale: '/Gateway/Wholesale/edit', // 塑料编辑批发商品
      listWholesale: '/Gateway/Wholesale/getList', // 塑料获取批发商品列表
      delWholesale: '/Gateway/Wholesale/del', // 塑料删除批发商品
      infoWholesale: '/Gateway/Wholesale/info', // 塑料批发商品详情
    },
    marketingClass: '/PromotionClassification/list', //营销中心分类
    marketingPlugin: '/SalesPlugin/list', //营销组件
    shopRewardList: '/GoodsReward/index',
    goodsReward: '/GoodsReward/getIndex',
    goodsRewardOne: '/GoodsReward/getOne',
    goodsRewardAdd: '/GoodsReward/add',
    goodsRewardEdit: '/GoodsReward/edit',
    goodsRewardDel: '/GoodsReward/delete',
    regionalShareholders: {
      list: '/Shareholder/list', // 区域股东列表
      setDefault: '/Shareholder/setDefault', // 设置是否启用股东信息
      del: '/Shareholder/del', // 删除股东
      notSelectList: '/Shareholder/notSelectList', // 获取可以筛选的城市
      notEditSelectList: '/Shareholder/notEditSelectList', // 编辑时筛选城市
      getUserList: '/Shareholder/getUserList', // 获取用户列表
      ShareholderAdd: '/Shareholder/add', // 添加区域代理
      info: '/Shareholder/info', // 获取股东详情
      edit: '/Shareholder/edit', // 编辑股东
      updateStatus: '/ShopArea/updateStatus', // 天之福 审核接口
      getLog: '/ShopArea/getLog', // 审核记录
      getSet: '/ShopAreaSet/get', // 获取设置
      add: '/ShopAreaSet/add', //修改设置
      getDistrbution: '/ShopArea/getDistrbution', // 普通员工列表
      upIdentity: '/ShopArea/upIdentity', // 升级成为区域经理
      updateIdentity: '/ShopArea/updateIdentity', // 降级为普通员工
    },
    groupUserList: '/Group/userList',
    cdkeyInfo: '/Coupon/cdkeyInfo',
    couponInfo: '/Coupon/info',
  },
  data: {
    survey: '/Statistics/overview', //概况
    analysis: '/Statistics/transactionAnalysis', //分析
    goodsAnalyse: '/Goods/goodsAnalyse', //商品分析
    goodsData: '/Goods/goodsData', //商品数据
    goodsRankingList: '/Goods/goodsRankingList', //商品排行榜
    goodsRankingListExport: '/Goods/goodsRankingListExport', //导出商品排行榜
    dataOverView: '/NewStatistics/dataOverView',
    goodsAnalyse1: '/GoodsStatistics/goodsAnalyse',
    salesAnalyse: '/GoodsStatistics/salesAnalyse',
    abnormalGoods: '/GoodsStatistics/abnormalGoods',
    goodsDetail: '/GoodsStatistics/goodsDetail',
    ankingList: '/GoodsStatistics/ankingList',
    storeRankingList: '/GoodsStatistics/storeRankingList',
    refundGoods: '/GoodsStatistics/refundGoods',
    getThirtyData: '/NewStatistics/getThirtyData',
    successRefund: '/NewStatistics/successRefund',
    conversion: '/NewStatistics/conversion',
    realTimeOverview: '/NewStatistics/realTimeOverview',
    todayTrend: '/NewStatistics/todayTrend',
    store_transaction: '/NewStatistics/store_transaction',
    getRefundBlock: '/NewStatistics/getRefundBlock',
  },
  // 店铺装修
  shopDiy: {
    bottomSet: '/ShopDiypage/getSetUpList',
    setSetUp: '/ShopDiypage/setSetUp',
    setInfo: '/setting/info',
    ShopColor: '/ShopDiypage/getShopColorScheme',
    PersonUpList: '/ShopDiypage/getPersonUpList',
    getPageList: '/ShopDiypage/getShopDiypageList',
    ShopPlugin: '/setting/getShopPlugin',
    areaList: '/area/list',
    shopinfo: '/Shop/info',
    setBase: '/Shop/setBaseInfo',
    createOrUpdatePage: '/ShopDiypage/createOrUpdatePage',
    setPersonUp: '/ShopDiypage/setPersonUp',
    getSystemList: '/ShopDiypage/getSystemDiypageList',
    ShopDiypage: '/ShopDiypage/getSystemDiypageInfo',
    listdel: '/ShopDiypage/del',
    copyDiypage: '/ShopDiypage/copyDiypage',
    isShow: '/ShopDiypage/IsShow',
    Drafts: '/ShopDiypage/createOrUpdatePageDrafts',
    DraftsList: '/ShopDiypage/getSystemDiypageDraftsList',
    deleteDrafts: '/ShopDiypage/delDrafts',
    DraftsInfo: '/ShopDiypage/getSystemDiypageDraftsInfo',
    getQrcode: '/ShopDiypage/getQrcode', //获取二维码
    templatelist: '/TemplateIndustry/list', //模版行业列表
    temItemList: '/TemplateCreation/list', // 行业模版列表
    createOrder: '/TemplateOrder/createOrder', //创建购买模版订单
    getOrderStatus: '/TemplateOrder/getOrderStatus', // 查询订单状态
    getTemplateInfo: '/ShopDiypage/getSystemDiypageTemplateInfo', // 获取模板数据
    myTemList: '/TemplateUsers/list', // 查询我的模板列表
    addMyTem: '/TemplateUsers/add', // 加入我的模板
    releaseTemplate: '/ShopDiypage/releaseTemplate', // 申请创建模版
    shopGetInfo: '/Shop/getInfo', // 获取店铺总览
    myList: '/TemplateCreation/myList', // 我申请的模版列表
    auditLogList: '/TemplateCreation/auditLogList', // 审核记录列表
    getSharerLists: '/WxShop/Sharer/getSharerLists', // 获取视频号分享员列表
    bindSharer: '/WxShop/Sharer/bind', // 绑定分享员
    unbindSharer: '/WxShop/Sharer/unbind', // 解绑分享员
  },
  // 首页
  homeIndex: {
    indexData: '/Statistics/quantity',
    setBaseInfo: '/Shop/setBaseInfo',
    readMsg: '/shop/readMessage',
    tabsMessage: '/Message/search',
    typeList: '/Message/typeList',
    unReadCount: '/Message/unReadCount',
  },
  // 上传文件
  upload: {
    addCert: '/Upload/addCert',
    addIco: '/Upload/addIco', // 上传ico图标
  },
  finance: {
    RechargeOrder: '/Recharge/order', //充值
    RechargeGetList: '/Recharge/getList', //获取短信充值列表
    getStatus: '/Recharge/getStatus', //获取支付状态
    cashLogList: '/Distribution/cashLogList',
  },
  // 素材中心
  sourceCenter: {
    ImagesGroupList: '/ImagesGroup/list', //所有图片分组
    ImagesGroupSort: '/ImagesGroup/sort', //图片分组排序
    ImagesGroupAdd: '/ImagesGroup/add', // 添加图片分组
    ImagesGroupEdit: '/ImagesGroup/edit', // 修改图片分组
    ImagesGroupDel: '/ImagesGroup/del', // 删除图片分组
    ImageMove: '/Image/move', // 移动图片分组
    ImageDel: '/Image/dels', // 删除图片
    UpdateName: '/Image/rename', //修改图片名字
    JwtList: '/JwtImages/JwtList', // 站点外获取图片分类
    rename: '/ImagesGroup/rename', // 修改文件夹名字
    moveGroup: '/ImagesGroup/move', // 移动文件夹
  },
  //收银台
  cashier: {
    CashierUsersList: '/CashierUsers/list', //账号列表
    CashierUsersAdd: '/CashierUsers/add', //添加账号
    CashierUsersEdit: '/CashierUsers/edit', //编辑账号
    CashierUsersDel: '/CashierUsers/del', //删除账号
    CashierOrder: '/Order/cashierOrder', //收银订单列表
    CashierOrderStatus: '/Order/cashierOrderStatus', //收银订单状态列表
    PrinterList: '/Printer/list', //打印机设备列表
    PrinterAdd: '/Printer/add', //添加打印机设备
    CashierPrinterConfig: '/PrinterConfig/info', //获取打印机配置信息
    CashierPrinterEdit: '/PrinterConfig/edit', //编辑打印机配置信息
    CashierLogin: '/Cashier/Login/login',
    GetClassify: '/Cashier/Goods/getClassify', //获取分类列表接口
    GetList: '/Cashier/Goods/list', // 商品列表接口
    GetInfo: '/Cashier/Goods/info', //获取多规格接口
    GetMemberInfo: '/Cashier/Member/info', //取会员信息接口
    GetColorScheme: '/Cashier/Login/getColorScheme', // 获取配色方案
    Buycreate: '/Cashier/Buy/create', //创建订单(计算金额)
    GetCouponList: '/Cashier/Member/userCouponList', //获取会员优惠券
    ScanGoods: '/Cashier/Goods/scan', //扫编码获取商品
    GetPayment: '/Cashier/Login/getPayment', //获取支付方式,
    BayPay: '/Cashier/Buy/pay', //支付
  },
  //分销
  distribution: {
    list: '/Distribution/list', //分销员列表
    distributorInfo: '/Distribution/info',
    contract: '/Distribution/getContract',
    userList: '/Distribution/userList', //用户列表
    add: '/Distribution/add', //添加分销员
    edit: '/Distribution/edit', //编辑分销员
    UpStatus: '/Distribution/UpStatus', //清退分销员
    relationComeFrom: '/Distribution/relationComeFrom', //来源列表
    relationPicture: '/Distribution/relationPicture', //分销关系图
    relation: '/Distribution/relation', //关系查询
    relationList: '/Distribution/relationList', //分销关系列表
    levelList: '/DistributionLevelSet/list', //等级列表
    levelList2: '/DistributionLevelSet/getLevel', //等级列表
    levelAdd: '/DistributionLevelSet/add', //添加等级
    levelEdit: '/DistributionLevelSet/edit', //编辑等级
    levelDel: '/DistributionLevelSet/del', //删除等级
    distributionSetInfo: '/DistributionLevelSet/distributionSetInfo', //分佣详情
    editCentCommission: '/DistributionSet/editCentCommission', //编辑分佣
    goodsList: '/DistributionGoodsSet/lists',
    goodsDetail: '/DistributionGoodsSet/info',
    goodsSet: '/DistributionGoodsSet/edit',
    goodsCancel: '/DistributionGoodsSet/cancel',
    shareSetInfo: '/DistributionShareSet/info',
    editShareSetInfo: '/DistributionShareSet/edit',
    setInfo: '/DistributionSet/info', //分销/分佣设置信息
    setInfo2: '/DistributionRuleSet/info', //规则/字段设置
    editFields: '/DistributionRuleSet/editFields', //字段编辑
    editAgreement: '/DistributionRuleSet/editAgreement', //编辑分销协议
    editRule: '/DistributionRuleSet/editRule', //编辑规则
    editDistribution: '/DistributionSet/editDistribution', //分销设置编辑
    auditList: '/DistributionAudit/list', //分销员审核列表
    auditInfo: '/DistributionAudit/info', //查看分销表单信息
    auditLogList: '/DistributionAudit/distributionAuditLogList', //分销审核记录列表
    auditEditStatus: '/DistributionAudit/editStatus', //分销审核
    editLevelType: '/DistributionSet/editLevelType', //等级设置条件编辑
    withdrawList: '/DistributionWithdraw/list', //提现列表
    withdrawAudit: '/DistributionWithdraw/audit', //提现审核
    withdrawInfo: '/DistributionWithdrawSet/info', //获取提现设置
    withdrawEdit: '/DistributionWithdrawSet/edit', //编辑提现设置
    shareSetInfo: '/DistributionShareSet/info', //分享设置信息
    shareSetEdit: '/DistributionShareSet/edit', //分享设置编辑信息
    commissionLog: '/DistributionCommissionLog/list', //佣金明细
    editTier: '/DistributionSet/editTier', //分销层次编辑
    lockingUserList: '/Distribution/lockingUserList', //潜在客户
    exportCommissionLog: '/DistributionCommissionLog/export',
    //美业项目新增的分销接口
    serviceSetList: '/Beauty/DistributionServiceDiscountSet/list',
    serviceSetAdd: '/Beauty/DistributionServiceDiscountSet/add',
    serviceSetEdit: '/Beauty/DistributionServiceDiscountSet/edit',
    serviceSetDel: '/Beauty/DistributionServiceDiscountSet/del',
    serviceList: '/Beauty/DistributionServiceSet/list',
    serviceInfo: '/Beauty/DistributionServiceSet/info',
    serviceEdit: '/Beauty/DistributionServiceSet/edit',
    serviceCancel: '/Beauty/DistributionServiceSet/cancel',
  },
  //拼团
  group: {
    selectableGoods: '/Group/selectableGoods',
    add: '/Group/add',
    list: '/Group/list',
    info: '/Group/info',
    showSet: '/Group/showSet',
    del: '/Group/del',
    edit: '/Group/edit',
    pageGoods: '/Group/pageGoods',
    goodsSkuList: '/Group/goodsSkuLists',
  },
  //系统管理
  admin: {
    accountManagement: {
      getAll: '/WebsiteUser/getAll', // 账号列表
      recharge: '/WebsiteUser/recharge', // 系统给站点充值
      userUpdate: '/WebsiteUser/userUpdate', // 系统修改密码
      userAccountLog: '/WebsiteUser/userAccountLog', // 获取记录
    },
    websiteTemplate: {
      industryList: '/WebsiteTemplateIndustry/list', // 行业列表
      industryAdd: '/WebsiteTemplateIndustry/add', // 添加行业
      industryEdit: '/WebsiteTemplateIndustry/edit', // 编辑行业
      industryDel: '/WebsiteTemplateIndustry/del', // 删除行业
      examineList: '/WebsiteTemplateCreation/list', // 审核列表
      auditLogList: '/WebsiteTemplateCreation/auditLogList', // 审核记录列表
      audit: '/WebsiteTemplateCreation/audit', // 审核模版
    },
    ApplicationManagement: {
      list: '/Account/list', // 应用管理列表
      listCount: '/Account/listCount', // 获取应用管理数量
      openVirtual: '/account/openVirtual', // 开启虚拟量
    },
    versionManagement: {
      wxList: '/WebsiteWxVersion/getList', // 微信版本列表
      aliList: '/WebsiteAliVersion/getList', // 支付宝版本列表
      baiduList: '/WebsiteBdVersion/getList', // 百度版本列表
      wxAdd: '/WebsiteWxVersion/add',
      aliAdd: '/WebsiteAliVersion/add',
      baiduAdd: '/WebsiteBdVersion/add',
      wxDel: '/WebsiteWxVersion/delete',
      aliDel: '/WebsiteAliVersion/delete',
      baiduDel: '/WebsiteBdVersion/delete',
    },
    template: {
      typeList: '/WebsiteTemplateIndustry/list', // 行业分类列表
    },
    // 站点升级续费
    upRenew: {
      upgrade: '/Page/upgrade', // 获取升级续费内容
      getPreOrder: '/Page/getPreOrder', // 站点预创建升级订单
      upgradePay: '/Page/upgradePay', // 获取站点套餐升级支付
      orderFind: '/Page/orderFind', // 检测站点套餐升级支付状态
    },
    caseList: '/WebsiteCase/list',
    caseDel: '/WebsiteCase/del',
    caseAdd: '/WebsiteCase/add',
    caseEdit: '/WebsiteCase/edit',
    bannerList: '/WebsiteBanner/list',
    bannerAdd: '/WebsiteBanner/add',
    bannerEdit: '/WebsiteBanner/edit',
    bannerDel: '/WebsiteBanner/del',
    articleList: '/WebsiteArticle/list',
    articleAdd: '/WebsiteArticle/add',
    articleDel: '/WebsiteArticle/del',
    articleEdit: '/WebsiteArticle/edit',
    helpTypeList: '/WebsiteArticleCase/list',
    addHelpType: '/WebsiteArticleCase/add',
    editHelpType: '/WebsiteArticleCase/edit',
    delHelpType: '/WebsiteArticleCase/del',
    orderList: '/Account/OrderList',
    version: '/WebsiteWxVersion/getList', // 版本管理
    versionAddWX: '/WebsiteWxVersion/add',
    versionAddAli: '/WebsiteAliVersion/add',
    versionAddBd: '/WebsiteBdVersion/add',
    versionEdit: '/WebsiteWxVersion/edit',
    versionDel: '/WebsiteWxVersion/delete',
    caseList: '/AccountMeal/list',
    salesPlugin: '/SalesPlugin/list',
    addPlugin: '/SalesPlugin/add',
    editPlugin: '/SalesPlugin/edit',
    delPlugin: '/SalesPlugin/del',
    pluginClass: '/PromotionClassification/list',
    addPluginClass: '/PromotionClassification/add',
    editPluginClass: '/PromotionClassification/edit',
    delPluginClass: '/PromotionClassification/del',
    formData: '/WebsiteUser/formData',
    backstageMenu: '/WebsiteAuths/get_tree_list',
    addBackstageMenu: '/WebsiteAuths/addAuths',
    editBackstageMenu: '/WebsiteAuths/editAuths',
    editBackstageMenuSort: '/WebsiteAuths/editAuthsSystem',
    delBackstageMenu: '/WebsiteAuths/delAuths',
    storeMenu: '/WebsiteAuths/getAuths',
    addStoreMenu: '/WebsiteAuths/add',
    editStoreMenu: '/WebsiteAuths/edit',
    addStoreMenuSort: '/WebsiteAuths/editSystem',
    delStoreMenu: '/WebsiteAuths/del',
    mealList: '/AccountMeal/list',
    addMeal: '/AccountMeal/add',
    editMeal: '/AccountMeal/edit',
    delMeal: '/AccountMeal/del',
    caseList: '/AccountMeal/caseList',
    addCase: '/AccountMeal/addCase',
    editCase: '/AccountMeal/editCase',
    delCase: '/AccountMeal/delCase',
    appletAuthentication: '/WebsiteMiniProgram/getList',
    rechargeLog: '/WebsiteRecharge/getList',
    renewMealList: '/AccountMeal/renewMealList',
    addRenewMeal: '/AccountMeal/addRenewMeal',
    editRenewMeal: '/AccountMeal/editRenewMeal',
    delRenewMeal: '/AccountMeal/delRenewMeal',
  },
  //同城版接口
  samecity: {
    //营销类
    marketingList: '/SamecityMarketing/list',
    addMarketing: '/SamecityMarketing/add',
    editMarketing: '/SamecityMarketing/edit',
    delMarketing: '/SamecityMarketing/del',
    editIsRights: '/SamecityMarketing/editIsRights',
    //设置类
    rightSet: '/SamecityRightsSet/list',
    editRight: '/SamecityRightsSet/edit',
    depositInfo: '/SamecityDepositSet/info',
    depositSet: '/SamecityDepositSet/edit',
    serviceAgreementEdit: '/SamecityServiceAgreement/edit',
    serviceAgreementInfo: '/SamecityServiceAgreement/info',
    //财务类
    depositList: '/SamecityMemberDepositOrder/list',
    depositOut: '/SamecityMemberLogout/list',
    depositOutAudit: '/SamecityMemberLogout/editStatus',
    cashLogList: '/ServiceOrder/cashLogList',
    ServiceIncomeList: '/ServiceOrder/ServiceIncomeList', // 服务收益列表
    GoodsIncomelist: '/Order/GoodsIncomelist', // 商品收益明细
    FinanceList: '/Finance/memberSettledEarningsList', // 入驻收益明细
    SameCityAdList: '/SameCityAd/getMoney', // 广告收益明细
    getPostsMoney: '/SameCityForumsPosts/getPostsMoney', // 论坛收益明细
    selectCityList: '/Shareholder/selectList', // 获取筛选出来的城市
    applyCashList: '/ServiceOrder/getApplyCashList',
    serviceOrderCheck: '/ServiceOrder/check',
    serviceOrderPayment: '/ServiceOrder/payment',
    distributionList: '/Distribution/distributionList', // 员工结算接口
    settlement: '/Distribution/settlement', //确认结算
    //服务类
    service: {
      classifyList: '/SamecityServiceClassify/list',
      addClassify: '/SamecityServiceClassify/add',
      editClassify: '/SamecityServiceClassify/edit',
      delClassify: '/SamecityServiceClassify/del',
      serviceList: '/SamecityService/list',
      addService: '/SamecityService/add',
      serviceInfo: '/SamecityService/info',
      editService: '/SamecityService/edit',
      delService: '/SamecityService/del',
      editSort: '/SamecityService/editSort',
      orderList: '/ServiceOrder/list',
      orderNum: '/ServiceOrder/stateNum',
      orderInfo: '/ServiceOrder/info',
      comment: '/SamecityServiceComment/list',
      orderRefundList: '/ServiceOrderRefund/list',
      orderRefundNum: '/ServiceOrderRefund/stateNum',
      orderRefundInfo: '/ServiceOrderRefund/info',
      orderRefund: '/ServiceOrderRefund/handle',
      orderRefundWallet: '/ServiceOrderRefund/refund',
    },
    //权益类
    rights: {
      settledList: '/SamecityMemberAudit/list',
      settledInfo: '/SamecityMemberAudit/info',
      settledAudit: '/SamecityMemberAudit/editStatus',
      settledAuditLog: '/SamecityMemberAudit/memberAuditLog',
      sameCityAd: '/SameCityAd/index',
      auditSuccess: '/SameCityAd/updateStatus',
      auditError: '/SameCityAd/updateStatus',
      auditInfo: '/SameCityAd/getDetail',
      samecityMemberList: '/SamecityMember/list',
      eliminateMember: '/SamecityMember/editStatus',
      eliminateMemberInfo: '/SamecityMember/info',
      applyLogList: '/SamecityMember/applyLogList',
    },
    // 首页
    diyPageList: '/ShopDiypage/SameCityDiypageList', //获取装修详情
    diyPage: '/ShopDiypage/SameCityDiypage', //同城版本装修（首页商城论坛）
    //论坛
    forum: {
      forumsSet: '/SameCityForumsSet/add', //论坛设置
      forumsget: '/SameCityForumsSet/get', //论坛设置详情
      classifyList: '/SameCityForumsPostCate/index', //分类列表
      classifyAdd: '/SameCityForumsPostCate/add', //分类添加
      classifyEdit: '/SameCityForumsPostCate/edit', //分类编辑
      classifyDelete: '/SameCityForumsPostCate/delete', //分类删除
      classifyDetail: '/SameCityForumsPostCate/getOne', //分类详情
      postsList: '/SameCityForumsPosts/index', //帖子列表
      postsAdd: '/SameCityForumsPosts/add', //帖子添加
      postsEdit: '/SameCityForumsPosts/edit', //帖子编辑
      postsDetail: '/SameCityForumsPosts/getOne', //帖子详情
      postsDelete: '/SameCityForumsPosts/delete', //帖子删除
    },
    //社区种草
    community: {
      forumsSet: '/WxShop/ForumsSet/add', //论坛设置
      forumsget: '/WxShop/ForumsSet/get', //论坛设置详情
      classifyList: '/WxShop/ForumsPostCate/index', //分类列表
      classifyAdd: '/WxShop/ForumsPostCate/add', //分类添加
      classifyEdit: '/WxShop/ForumsPostCate/edit', //分类编辑
      classifyDelete: '/WxShop/ForumsPostCate/delete', //分类删除
      classifyDetail: '/WxShop/ForumsPostCate/getOne', //分类详情
      postsList: '/WxShop/ForumsPosts/index', //帖子列表
      postsAdd: '/WxShop/ForumsPosts/add', //帖子添加
      postsEdit: '/WxShop/ForumsPosts/edit', //帖子编辑
      postsDetail: '/WxShop/ForumsPosts/getOne', //帖子详情
      postsDelete: '/WxShop/ForumsPosts/delete', //帖子删除
    },
  },
  catering: {
    // 装修
    diypageList: '/Catering/ShopDiypage/diypageList',
    diypage: '/Catering/ShopDiypage/diypage',
  },
  VR: {
    list: '/VrList/Index/list', // 查询vr列表
    del: '/VrList/Index/del', // 删除vr
    add: '/VrList/Index/add', // 添加Vr
    edit: '/VrList/Index/edit', // 编辑vr
    info: '/VrList/Index/info', // 编辑vr
  },
  O2O: {
    recycler: {
      addRecyler: '/Recycle/Recyler/add', // 添加回收员
      editRecyler: '/Recycle/Recyler/edit',
      recylerList: '/Recycle/Recyler/list', // 回收员列表
      delRecyler: '/Recycle/Recyler/del', // 删除回收员
      recylerInfo: '/Recycle/Recyler/info', // 获取回收员信息
      userList: '/Recycle/User/list', // 用户列表
      userInfo: '/Recycle/User/info', // 获取用户信息
      setOpen: '/Recycle/Recyler/setOpen', // 更改回收员状态
    },
    ShopDiypage: {
      diypage: '/Recycle/ShopDiypage/diypage', // O2O装修首页
      diypageList: '/Recycle/ShopDiypage/diypageList', // 查询是否有首页装修
    },
    regionalAdministrator: {
      shareholderList: '/Recycle/Shareholder/list', // 获取股东列表
      getUserList: '/Recycle/Shareholder/getUserList', // 获取用户列表
      notSelectList: '/Recycle/Shareholder/notSelectList', // 添加股东时获取没有被注册股东的地区
      notEditSelectList: '/Recycle/Shareholder/notEditSelectList', // 编辑股东时获取地区列表
      addRegional: '/Recycle/Shareholder/add', // 添加区域管理员
      getRegionalInfo: '/Recycle/Shareholder/info', // 获取区域管理员详情
      editRegional: '/Recycle/Shareholder/edit', // 编辑区域管理员
      delRegional: '/Recycle/Shareholder/del',
    },
    basics: {
      getBasicsSet: '/Recycle/BasicSettings/info', // 获取基础设置详情
      editBasicsSet: '/Recycle/BasicSettings/edit', // 编辑基础设置
      editAgreement: '/Recycle/BasicSettings/editAgreement', // 编辑协议
    },
    order: {
      list: '/Recycle/Order/getList', // 订单列表
      orderInfo: '/Recycle/Order/info', // 订单详情
      cancle: '/Recycle/Order/cancle', // 取消订单
      getTotal: '/Recycle/Order/getTotal', // 获取各个订单的数量
      paiOrder: '/Recycle/Order/paiOrder', // 给回收员订单
    },
    finance: {
      withdrawList: '/Recycle/Withdraw/list', // 提现管理列表
      audit: '/Recycle/Withdraw/audit',
      caseList: '/Recycle/UserCashLog/list',
      recylerList: '/Recycle/UserCashLog/recylerList',
      settlementL: '/Recycle/UserCashLog/settlement',
      batchSettlement: '/Recycle/UserCashLog/batchSettlement',
    },
    addRecycle: '/Recycle/Goods/add',
    editRecycle: '/Recycle/Goods/edit',
    delRecycle: '/Recycle/Goods/del',
    delSpecDistrict: '/Recycle/Goods/delSpecDistrict',
    recycleList: '/Recycle/Goods/list',
    recycleEditSort: '/Recycle/Goods/editSort',
    recycleInfo: '/Recycle/Goods/info',
    delSpec: '/Recycle/Goods/delSpec',
    editStatus: '/Recycle/Goods/editStatus',
    distributionList: '/Recycle/Distribution/list',
    distributionAuditList: '/Recycle/DistributionAudit/list',
    distributionAudit: '/Recycle/DistributionAudit/audit',
    distributionUserList: '/Recycle/Distribution/inviteUserList',
    inviteUserList: '/Recycle/Distribution/inviteUserList',
    qrcode: '/Recycle/Distribution/getQrCode',
  },
  sports: {
    activityLists: '/Esport/Activity/lists', // 无分页活动列表
    lockTeamList: '/Esport/Activity/lockTeamList', // 月决赛全部比赛
    teamRankList: '/Esport/Activity/teamRankList', // 月决赛决赛排名
    setRanking: '/Esport/Activity/setRanking', // 设置队伍排名
    joinFinal: '/Esport/Activity/joinFinal', // 设置队伍是否加入决赛
    scheduleTeamRankList: '/Esport/ActivityEvents/teamRankList', // 赛程颁奖列表接口
    scheduleSetRanking: '/Esport/ActivityEvents/setRanking', // 赛程设置排名接口
    teamUserExport: '/Esport/Activity/teamUserExport', //导出战队成员接口
    diypage: '/Esport/ShopDiypage/diypage', // 自定义装修
    diypageList: '/Esport/ShopDiypage/diypageList', // 获取装修详情
    activityList: '/Esport/Activity/list',
    activityLists: '/Esport/Activity/lists',
    addActivity: '/Esport/Activity/add',
    editActivity: '/Esport/Activity/edit',
    delActivity: '/Esport/Activity/del',
    activityInfo: '/Esport/Activity/info',
    quickEdit: '/Esport/Activity/quickEdit',
    addSchedule: '/Esport/ActivityEvents/add',
    scheduleList: '/Esport/ActivityEvents/list',
    editSchedule: '/Esport/ActivityEvents/edit',
    quickEdit2: '/Esport/ActivityEvents/quickEdit',
    delSchedule: '/Esport/ActivityEvents/del',
    scheduleInfo: '/Esport/ActivityEvents/info',
    addVote: '/Esport/Vote/add',
    voteList: '/Esport/Vote/list',
    editVote: '/Esport/Vote/edit',
    delVote: '/Esport/Vote/del',
    voteInfo: '/Esport/Vote/info',
    votePersonList: '/Esport/Vote/votePersonList',
    votePersonAdd: '/Esport/Vote/votePersonAdd',
    votePersonEdit: '/Esport/Vote/votePersonEdit',
    votePersonDel: '/Esport/Vote/votePersonDel',
    teamList: '/Esport/ActivityEvents/teamList',
    teamUserList: '/Esport/ActivityEvents/teamUserList',
    voteResultList: '/Esport/Vote/voteResultList',
    voteUserList: '/Esport/Vote/voteUserList',
    drawLotsInfo: '/Esport/ActivityEvents/drawLotsInfo',
    drawLotsSubmit: '/Esport/ActivityEvents/drawLotsSubmit',
    updateStatus: '/Esport/ActivityEvents/updateStatus',
  },
  //自定义表单
  customform: {
    list: '/CustomForm/list',
    add: '/CustomForm/add',
    edit: '/CustomForm/edit',
    setIsDefault: '/CustomForm/setIsDefault',
    del: '/CustomForm/del',
    lists: '/CustomForm/lists',
    dataInfo: '/CustomForm/dataInfo', //提交表单数据详情
    dataList: '/CustomForm/dataList', //提交表单数据列表
  },
  // Tikmall官网
  Tikmall: {
    setBaseInfo: '/En/Website/setBaseInfo', // 设置店铺详情
    getInfo: '/En/Website/info', //获取网站详情
    getClassifyList: '/En/StoreClassify/list', // 获取商家分类详情
    addClassify: '/En/StoreClassify/add', // 添加商家分类
    editClassify: '/En/StoreClassify/edit', // 编辑商家分类
    delClassify: '/En/StoreClassify/del', // 删除商家分类
    getVideoClassifyList: '/En/ShortVideoClassify/list', // 获取短视频详情
    addVideoClassify: '/En/ShortVideoClassify/add', // 添加短视频
    editVideoClassify: '/En/ShortVideoClassify/edit', // 编辑短视频
    delVideoClassify: '/En/ShortVideoClassify/del', // 删除短视频
    videoList: '/En/ShortVideo/list', // 查询短视频列表
    quickEditVideoInfo: '/En/ShortVideo/editInfo', // 快速编辑（排序/是否显示）接口
    delVideoInfo: '/En/ShortVideo/del', // 删除短视频接口
    addVideoInfo: '/En/ShortVideo/add', // 添加短视频
    editVideoInfo: '/En/ShortVideo/edit', // 添加短视频
    getVideoInfo: '/En/ShortVideo/info', // 获取短视频详情
    keywordList: '/En/StoreKeyword/list', // 商家关键词列表接口
    delKeyword: '/En/StoreKeyword/del', // 删除商家关键词接口
    addKeyword: '/En/StoreKeyword/add', // 添加商家关键词
    editKeyword: '/En/StoreKeyword/edit', // 编辑商家关键词
    keywordInfo: '/En/StoreKeyword/info', //商家关键词详情
  },
  //美业
  beauty: {
    service: {
      serviceClassifyList: '/Beauty/ServiceClassify/list', // 服务分类列表
      delServiceClassifyList: '/Beauty/ServiceClassify/del', // 删除服务分类
      editServiceClassifyList: '/Beauty/ServiceClassify/edit', // 编辑服务分类
      addServiceClassifyList: '/Beauty/ServiceClassify/add', // 添加服务分类
      addService: '/Beauty/Service/add', // 添加服务
      serviceList: '/Beauty/Service/list', // 查询服务列表
      editStatus: '/Beauty/Service/editStatus', // 更改服务上架状态
      editInfo: '/Beauty/Service/editInfo', // 更改服务信息
      delService: '/Beauty/Service/del', // 删除服务
      serviceInfo: '/Beauty/Service/info', // 获取服务详情
      editService: '/Beauty/Service/edit', // 编辑服务
      addCard: '/Beauty/CardItem/add', // 添加卡项服务
      cardList: '/Beauty/CardItem/list', // 卡项列表 有分页
      cardEditStatus: '/Beauty/CardItem/editStatus', // 卡项编辑状态
      cardEditSort: '/Beauty/CardItem/editInfo', // 卡项编辑排序信息
      cardDel: '/Beauty/CardItem/del', // 删除卡项
      cardInfo: '/Beauty/CardItem/info', // 获取卡项信息
      noPage: '/Beauty/CardItem/lists', // 无分页获取卡项列表
      getLevel: '/Beauty/DistributionLevelSet/getLevel', // 获取分销等级列表
    },
    setMeal: {
      setMealList: '/Beauty/GoodsPackage/list', // 套餐管理列表
      editStatus: '/Beauty/GoodsPackage/editStatus', // 编辑套餐上下架状态
      delSetMeal: '/Beauty/GoodsPackage/del', // 删除套餐
      goodsList: '/Beauty/GoodsPackage/goodsList', // 获取商品列表
      addSetMeal: '/Beauty/GoodsPackage/add', // 添加套餐
      setMealInfo: '/Beauty/GoodsPackage/info', // 获取套餐详情
      editSetMeal: '/Beauty/GoodsPackage/edit', // 编辑套餐
    },
    //   预约
    setinfo: '/Beauty/AppointmentSet/info', //获取预约设置详情
    setedit: '/Beauty/AppointmentSet/edit', // 编辑预约设置
    lookBoard: '/Beauty/Appoint/lookBoard', //看板
    serveList: '/Beauty/Appoint/getList', //预约列表
    cancleServe: '/Beauty/Appoint/cancle', //取消预约
    toshop: '/Beauty/Appoint/toshop', //确认到店
    addServe: '/Beauty/Appoint/add', //添加预约
    serveDetail: '/Beauty/Appoint/detail', //预约详情
    diagnosisSetList: '/Beauty/DiagnosisSet/getList', //诊断设置列表
    diagnosisSetAdd: '/Beauty/DiagnosisSet/add', //添加诊断设置
    diagnosisSetEdit: '/Beauty/DiagnosisSet/edit', //编辑诊断设置
    diagnosisSetDel: '/Beauty/DiagnosisSet/del', //删除诊断设置
    diagnosisAdd: '/Beauty/Appoint/diagnosis', //添加诊断
    diagnosisList: '/Beauty/Appoint/getDiagnosisList', //诊断列表
    diagnosisDel: '/Beauty/Appoint/delDiagnosis', //删除诊断
    // 用户
    stafflist: '/Beauty/Staff/list', //获取员工列表
    userCanList: '/Beauty/Staff/userList', //获取可以选择的普通用户
    addStaff: '/Beauty/Staff/add', //添加员工
    editStaff: '/Beauty/Staff/edit', //编辑员工
    delStaff: '/Beauty/Staff/delStaff', //删除员工
    schedulList: '/Beauty/StaffScheduling/list', //获取排班日历表
    classifyList: '/Beauty/Staff/classifyList', //获取服务分类
    serviceList: '/Beauty/Staff/serviceList', //服务列表
    selectAbleStaff: '/Beauty/StaffScheduling/selectAbleStaff', //获取当前排版日期可选择医生
    setScheduling: '/Beauty/StaffScheduling/setScheduling', //员工排班
    editScheduling: '/Beauty/StaffScheduling/editScheduling', //编辑单个员工排班
    userList: '/Beauty/User/list', //获取普通用户列表
    userInfo: '/Beauty/User/info', //用户详情
    cardItemDetailLog: '/Beauty/User/getCardItemDetailLog', //获取用卡日志
    delUser: '/Beauty/User/delUser', //删除用户
    // 推广
    goodsList: '/Beauty/GoodsPackage/goodsList',
    mealList: '/Beauty/GoodsPackage/list',
    addMeal: '/Beauty/GoodsPackage/add',
    mealInfo: '/Beauty/GoodsPackage/info',
    editMeal: '/Beauty/GoodsPackage/edit',
    delMeal: '/Beauty/GoodsPackage/del',
    editMealStatus: '/Beauty/GoodsPackage/editStatus',
    //订单
    orderList: '/Beauty/Order/list',
    orderNum: '/Beauty/Order/stateNum',
    confirmPayment: '/Beauty/Order/confirmPayment',
    cancelOrder: '/Beauty/Order/cancel',
    orderInfo: '/Beauty/Order/info',
    //课程
    courseInfo: '/Beauty/Course/info',
    courseList: '/Beauty/Course/getList',
    courseAdd: '/Beauty/Course/add',
    courseEdit: '/Beauty/Course/edit',
    courseDel: '/Beauty/Course/del',
    courseCateAdd: '/Beauty/CourseCate/add',
    courseCateEdit: '/Beauty/CourseCate/edit',
    courseCateList: '/Beauty/CourseCate/getList',
    courseCateDel: '/Beauty/CourseCate/del',
    courseCateInfo: '/Beauty/CourseCate/info',
    courseCatalogAdd: '/Beauty/CourseCatalog/add',
    courseCatalogEdit: '/Beauty/CourseCatalog/edit',
    courseCatalogList: '/Beauty/CourseCatalog/getList',
    courseCatalogDel: '/Beauty/CourseCatalog/del',
    courseCatalogInfo: '/Beauty/CourseCatalog/info',
    courseOrder: '/Beauty/CourseOrder/getList',
    // 收银台
    cashier: {
      cardList: '/Beauty/Cashier/getCardList', //获取可以使用的服务项目卡
      createOrder: '/Beauty/Cashier/create', //创建订单
      pay: '/Beauty/Cashier/pay', //支付
      changePrice: '/Beauty/Order/changePrice', //修改订单价格
    },
  },
  OfficeWebsite: {
    setBaseInfo: '/En/OfficeWebsite/setBaseInfo',
    servicerList: '/En/Servicer/list',
    addServicer: '/En/Servicer/add',
    editServicer: '/En/Servicer/edit',
    delServicer: '/En/Servicer/del',
    memberList: '/En/Member/list',
    addMember: '/En/Member/add',
    editMember: '/En/Member/edit',
    delMember: '/En/Member/del',
  },
  movableBulletBox: {
    edit: '/ActivityPopout/edit', // 活动弹框设置接口
    info: '/ActivityPopout/info', // 活动弹框详情接口
  },
  PCOfficialWebsite: {
    contactUsList: '/En/OfficeWebsite/contactUsList', // 获取表单列表
    delContactUs: '/En/OfficeWebsite/delContactUs', // 删除表单
    subscribeList: '/En/OfficeWebsite/subscribeList', // 获取订阅列表
    delSubscribe: '/En/OfficeWebsite/delSubscribe', // 删除订阅列表
    setBaseInfo: '/En/OfficeWebsite/setBaseInfo', // 设置网站设置
    getSetInfo: '/En/OfficeWebsite/info', // 获取网站设置详情
    artList: '/En/Article/list', // 获取资讯列表
    addArt: '/En/Article/add', // 添加资讯
    artInfo: '/En/Article/info', // 资讯详情
    editArt: '/En/Article/edit', // 编辑资讯
    delArt: '/En/Article/del', // 删除资讯
    sortArt: '/En/Article/editInfo', // 快速编辑（是否置顶/是否推荐）接口
    getTextInfo: '/En/Text/info',
    editTextInfo: '/En/Text/edit',
    getDocumentList: '/En/Document/list',
    addDocument: '/En/Document/add',
    editDocument: '/En/Document/edit',
    delDocument: '/En/Document/del',
    getBannerList: '/En/Banner/list',
    addBanner: '/En/Banner/add',
    getBannerInfo: '/En/Banner/info',
    editBanner: '/En/Banner/edit',
    delBanner: '/En/Banner/del',
    editBannerInfo: '/En/Banner/editInfo',
  },
  // 零售版api
  retail: {
    userList: '/Retail/User/list', // 获取用户列表
    headerInfo: '/Retail/Header/info', // 获取团长详情
    addHead: '/Retail/Header/add', // 添加团长
    editHead: '/Retail/Header/edit', // 编辑团长
    headList: '/Retail/Header/list', // 团长列表
    setOpen: '/Retail/Header/setOpen', // 是否开启团长
    deleteHeder: '/Retail/Header/del', // 删除团长
    recover: '/Retail/Header/recover', // 恢复团长
    headerSet: '/Retail/Sets/edit', // 更新团长设置
    getHeadInfo: '/Retail/Sets/info', // 获取团长设置
    GoodsShareInfo: '/Share/GoodsShareInfo', // 获取商品分享设置
    GoodsShareEdit: '/Share/GoodsShareEdit', // 编辑商品分享设置
    delZonePrice: '/Goods/delZonePrice',
    getZonePriceInfo: '/Goods/getZonePriceInfo',
    userList: '/Retail/User/list', //用户列表
    diypage: '/Retail/ShopDiypage/diypage', //装修首页
    diypageInfo: '/Retail/ShopDiypage/info', //装修首页详情
    invoicesList: '/Invoices/list', //开票中心
    invoicesInfo: '/Invoices/info', //开票详情
    invoicesEdit: '/Invoices/editStatus', //开票审核
    newLogisticsQuery: '/Order/newLogisticsQuery', //发票物流信息
    sendEmail: '/Invoices/sendEmail', //发送邮箱
    setDelivery: '/order/setDelivery', //确认配送
  },
  integral: {
    integralInfo: '/IntegralSet/info', // 积分设置详情
    integralEdit: '/IntegralSet/edit', // 编辑积分设置
    integralGoods: {
      list: '/IntegralGoods/list', //  积分商品列表
      info: '/IntegralGoods/info', // 积分商品详情接口
      add: '/IntegralGoods/add', // 添加积分商品
      edit: '/IntegralGoods/edit', // 添加积分商品
      del: '/IntegralGoods/del', // 删除积分商品
      editInfo: '/IntegralGoods/editInfo', // 编辑积分商品信息接口
    },
    order: {
      orderStatus: '/IntegralOrder/orderStatus',
      list: '/IntegralOrder/list',
      updateStatus: '/IntegralOrder/updateStatus',
      logisticsQuery: '/IntegralOrder/logisticsQuery',
      info: '/IntegralOrder/info',
      editExpress: '/IntegralOrder/editExpress',
    },
  },
  //限时抢购
  flashSale: {
    getList: '/FlashSale/Activity/getList', //限时抢购列表
    add: '/FlashSale/Activity/add',
    edit: '/FlashSale/Activity/edit',
    getGoodList: '/FlashSale/Activity/getGoodList', //获取可以限时抢购的商品列表
    action: '/FlashSale/Activity/action', //中止活动
    delActivityGoods: '/FlashSale/Activity/delActivityGoods', //删除活动商品
    delActivity: '/FlashSale/Activity/delActivity', //删除活动
    getInfo: '/FlashSale/Activity/getInfo',
  },
  Newcomer: {
    getList: '/Retail/Newcomer/getList', //限时抢购列表
    add: '/Retail/Newcomer/add',
    edit: '/Retail/Newcomer/edit',
    getGoodList: '/Retail/Newcomer/getGoodList', //获取可以限时抢购的商品列表
    action: '/Retail/Newcomer/action', //中止活动
    delActivityGoods: '/Retail/Newcomer/delActivityGoods', //删除活动商品
    delActivity: '/Retail/Newcomer/delActivity', //删除活动
    getInfo: '/Retail/Newcomer/getInfo',
  },
  gateway: {
    service: {
      ServiceClassify: '/Gateway/ServiceClassify/list',
      addServiceClassify: '/Gateway/ServiceClassify/add',
      editServiceClassify: '/Gateway/ServiceClassify/edit',
      delServiceClassify: '/Gateway/ServiceClassify/del',
      addService: '/Gateway/Service/add', // 添加服务
      serviceList: '/Gateway/Service/list', // 查询服务列表
      editStatus: '/Gateway/Service/setPutaway', // 更改服务上架状态
      editInfo: '/Gateway/Service/editInfo', // 更改服务信息
      delService: '/Gateway/Service/del', // 删除服务
      serviceInfo: '/Gateway/Service/info', // 获取服务详情
      editService: '/Gateway/Service/edit', // 编辑服务
      threeLevelList: '/Gateway/ServiceClassify/threeLevelList', //获取三级分类
      serviceComment: '/Gateway/ServiceComment/list',
      serviceCommentReply: '/Gateway/ServiceComment/reply',
      serviceCommentStatus: '/Gateway/ServiceComment/setStatus',
    },
    member: {
      depositList: '/Gateway/DepositSet/list',
      depositInfo: '/Gateway/DepositSet/info',
      depositAdd: '/Gateway/DepositSet/set',
      depositDel: '/Gateway/DepositSet/dels',
      discountInfo: '/Gateway/MemberDiscountSet/info',
      discountInfoEdit: '/Gateway/MemberDiscountSet/edit',
      list: '/Gateway/Member/list',
      del: '/Gateway/Member/delMember',
      info: '/Gateway/Member/info',
      apply: '/Gateway/MemberAudit/list',
      setList: '/Gateway/MemberSet/list',
      memberSetEdit: '/Gateway/MemberSet/set',
      memberSetDel: '/Gateway/MemberSet/dels',
      auditInfo: '/Gateway/MemberOrder/MemberAuditinfo',
      audit: '/Gateway/MemberAudit/editStatus',
      ruleSet: '/Gateway/MemberSet/ruleSet',
      secondLevelList: '/Gateway/ServiceClassify/secondLevelList',
      secondLevelList2: '/Gateway/GoodsClassify/secondLevelList',
      retirement: '/Gateway/Member/editStatus',
      partnerOrder: '/Gateway/MemberOrder/list',
      auditPartnerOrder: '/Gateway/MemberOrder/editStatus',
      shopGoods: '/Gateway/ConsignmentGoods/list',
      shopService: '/Gateway/ConsignmentService/list',
      consignmentGoods: '/Gateway/ConsignmentGoods/consignmentList',
      consignmentService: '/Gateway/ConsignmentService/consignmentList',
      soldGoodsOut: '/Gateway/ConsignmentGoods/soldOut',
      soldServiceOut: '/Gateway/ConsignmentService/soldOut',
      commissionSet: '/Gateway/MemberCommissionSet/info',
      commissionEdit: '/Gateway/MemberCommissionSet/edit',
      cashLogList: '/Gateway/Member/cashLogList',
      integralLog: '/Gateway/IntegralLog/list',
    },
    activity: {
      getList: '/Project/Activity/getList', // 活动列表
      addActivity: '/Project/Activity/add', // 添加活动
      editActivity: '/Project/Activity/edit', // 添加活动
      delActivity: '/Project/Activity/del', // 删除活动
      infoActivity: '/Project/Activity/info', // 活动详情
      isUp: '/Project/Activity/up', // 上/架活动
      getOrderList: '/Project/Activity/getOrderList', // 获取活动订单列表
    },
    cooperation: {
      getList: '/Project/Cooperation/getList', //合作列表
      add: '/Project/Cooperation/add', // 添加合作
      edit: '/Project/Cooperation/edit', // 编辑合作
      info: '/Project/Cooperation/info', // 查看合作详情
      del: '/Project/Cooperation/del', // 删除合作项
      up: '/Project/Cooperation/up', //上下架
      getJoinList: '/Project/Cooperation/getJoinList', // 合作申请列表
      updateStatus: '/Project/Cooperation/updateStatus', // 合作申请列表通过（推荐）/驳回
    },
    IntegralSet: {
      edit: '/Gateway/IntegralSet/edit', // 编辑积分设置
      info: '/Gateway/IntegralSet/info', // 获取积分设置
    },
    setting: {
      add: '/Project/Mset/add', // 合作设置（修改/添加）
      info: '/Project/Mset/info', // 合作设置详情
    },
    Ad: {
      classify_list: '/Gateway/Ad/ClassifyList',
      list: '/Gateway/Ad/list',
      add: '/Gateway/Ad/add',
      info: '/Gateway/Ad/info',
      edit: '/Gateway/Ad/edit',
      del: '/Gateway/Ad/del',
      setPutaway: '/Gateway/Ad/setPutaway',
      setSort: '/Gateway/Ad/setSort',
      AdSet: '/Gateway/AdSet/info',
      editExplains: '/Gateway/AdSet/editExplains',
      editPrice: '/Gateway/AdSet/editPrice',
      order: '/Gateway/AdOrder/list',
      orderInfo: '/Gateway/AdOrder/info',
      orderAudit: '/Gateway/AdOrder/audit',
      orderStatus: '/Gateway/AdOrder/getStatus',
      showTime: '/Gateway/AdOrder/getShowTime',
    },
    keywords: {
      setInfo: '/Gateway/KeywordSet/info',
      editExplains: '/Gateway/KeywordSet/editExplains',
      editPrice: '/Gateway/KeywordSet/editPrice',
      list: '/Gateway/Keyword/list',
      orderStatus: '/Gateway/KeywordOrder/getStatus',
      orderList: '/Gateway/KeywordOrder/list',
      orderInfo: '/Gateway/KeywordOrder/info',
      audit: '/Gateway/KeywordOrder/audit',
      consumeLog: '/Gateway/Keyword/consumeLog',
    },
    currentprice: {
      setInfo: '/Gateway/CurrentPricesSet/info',
      editSubscriptionPrice: '/Gateway/CurrentPricesSet/editSubscriptionPrice',
      editSubscriptionDiscount: '/Gateway/CurrentPricesSet/editSubscriptionDiscount',
      editExplains: '/Gateway/CurrentPricesSet/editExplains',
      list: '/Gateway/CurrentPrices/list',
      import: '/Gateway/CurrentPrices/import',
      getTemplate: '/Gateway/CurrentPrices/getTemplate',
      classify: '/Gateway/CurrentPricesSet/currentPricesClassify',
      add: '/Gateway/CurrentPrices/add',
      del: '/Gateway/CurrentPrices/del',
      auditList: '/Gateway/CurrentPricesAudit/list',
      audit: '/Gateway/CurrentPricesAudit/audit',
      getStatus: '/Gateway/CurrentPricesOrder/getStatus',
      orderList: '/Gateway/CurrentPricesOrder/list',
      cancelOrder: '/Gateway/CurrentPricesOrder/cancel',
    },
    indexset: {
      info: '/Gateway/Set/info',
      set: '/Gateway/Set/set',
    },
    finance: {
      platformRevenue: '/Gateway/PlatformRevenueLog/list',
      withdrawalList: '/Gateway/Withdraw/list',
      withdrawalStatus: '/Gateway/Withdraw/getStatus',
      withdrawalAudit: '/Gateway/Withdraw/audit',
    },
    proxy: {
      setinfo: '/Gateway/ContractSet/info',
      setadd: '/Gateway/ContractSet/add',
      setedit: '/Gateway/ContractSet/edit',
      getLists: '/Gateway/Contract/getLists',
      getPlatformList: '/Gateway/Contract/getPlatformList',
    },
  },
  //聚特汇
  serviceProvider: {
    serviceProviderList: '/Facilitator/getList',
    addServiceProvider: '/Facilitator/add',
    editServiceProvider: '/Facilitator/edit',
    delServiceProvider: '/Facilitator/del',
    auditServiceProvider: '/Facilitator/check',
    serviceProviderCheck: '/Facilitator/check',
    BalanceLog: '/WxShop/BalanceLog/list',
    withdrawList: '/WxShop/Withdraw/list',
    withdrawAudit: '/WxShop/Withdraw/audit',
    toMoney: '/WxShop/Withdraw/toMoney', // 打款到微信零钱
    createZip: '/Facilitator/createZip',
    wechatUserWithdraw: '/WxShop/WechatUserWithdraw/list',
    wechatUserWithdrawAudit: '/WxShop/WechatUserWithdraw/audit',
    getEarningsOrder: '/Facilitator/getEarningsOrder', // 获取收益订单
    getAllEarningsOrder: '/Facilitator/getAllEarningsOrder', // 获取全部收益订单
    getEarningsDetail: '/Facilitator/getEarningsDetail', // 获取收支明细
    staffList: '/WxShop/Staff/list',
    addStaff: '/WxShop/Staff/add',
    editStaff: '/WxShop/Staff/edit',
    delStaff: '/WxShop/Staff/del',
    regimenList: '/ShopRegimental/getList',
    regimenCheck: '/ShopRegimental/check',
    materialList: '/WxShop/Material/list',
    addMaterial: '/WxShop/Material/add',
    editMaterial: '/WxShop/Material/edit',
    delMaterial: '/WxShop/Material/del',
    materialCateList: '/WxShop/MaterialCate/list',
    addMaterialCate: '/Wxshop/MaterialCate/add',
    editMaterialCate: '/Wxshop/MaterialCate/edit',
    delMaterialCate: '/Wxshop/MaterialCate/del',
    containerList: '/WxShop/Container/getList',
    containerCheck: '/WxShop/Container/check',
    payAudit: '/WxShop/QuanzhenPay/getList',
    payAuditCheck: '/WxShop/QuanzhenPay/check',
    payAuditSend: '/WxShop/QuanzhenPay/setSend',
    posterList: '/WxShop/Poster/getList',
    addPoster: '/WxShop/Poster/add',
    editPoster: '/WxShop/Poster/edit',
    delPoster: '/WxShop/Poster/del',
    getPreAuthorizationUrl: '/Jutwitter/WxCode/getPreAuthorizationUrl', // 获取微信授权链接
    getWxapp: '/Jutwitter/WxCode/getWxapp', // 获取维系授权信息
    setWebView: '/Jutwitter/WxCode/setWebView', // 配置业务域名
    getAuditStatus: '/Jutwitter/WxCode/getAuditStatus', // 获取审核状态
    wxqrCode: '/Jutwitter/WxCode/qrCode', //wx获取体验码
    testerList: '/Jutwitter/WxCode/testerList', // 体验者列表
    wxtesterUnbind: '/Jutwitter/WxCode/testerUnbind', //wx解绑体验者
    wxtesterBind: '/Jutwitter/WxCode/testerBind', //wx绑定体验者

    commit: '/Jutwitter/WxCode/commit', // 上传小程序代码
    setWxapp: '/Jutwitter/WxCode/setWxapp', // 设置基础设置
    submitAudit: '/Jutwitter/WxCode/submitAudit', // 提交审核
    undocodeaudit: '/Jutwitter/WxCode/undocodeaudit', //  撤销审核
    release: '/Jutwitter/WxCode/release', // 发布小程序
    handleAuthorize: '/api/JuTwitter/Wxticket/handleAuthorize', // 回调设置站定信息
    getThirdLog: '/Jutwitter/WxCode/getThirdLog', // 获取审核记录 0
    checkOpenPublisher: '/Jutwitter/WxCode/checkOpenPublisher', // 检测是否能开通流量主
    createPublisher: '/Jutwitter/WxCode/createPublisher', // 开通流量主
    createAdunit: '/Jutwitter/WxCode/createAdunit', // 创建广告单元
    facilitatorBlack: '/Facilitator/isBlack',
    isWifiLimitVaild: '/Facilitator/isWifiLimitVaild', // 是否限制wifi
    isStoreLimitVaild: '/Facilitator/isStoreLimitVaild', // 是否限制激励
    isLimitUserLinkWifi: '/Facilitator/isLimitUserLinkWifi', // 是否限制连续wifi激励
    isPrivate: '/WxShop/Store/isPrivate', // 商家是否开启私域
    WifiSettlement: '/WxShop/WifiSettlement/list', // 用户连接wifi次数
    storeExport: '/WxShop/Store/export', // 导出商家列表

    storeImport: '/WxShop/Store/importMercode', // 导入商户号
    companyGoods: '/WxShop/CompanyGoods/list', // 企业商品列表
    companyGoodsSkusCps: '/WxShop/CompanyGoodsSkusCps/list', // 企业cps推广商品
    catsList: '/WxShop/CompanyGoods/catsList', // 企业自营商品 视频号商品分类列表
    addGoods: '/WxShop/CompanyGoods/add', // 企业添加商品
    upStatus: '/WxShop/CompanyGoods/upStatus', // 更改企业商品状态
    delCGoods: '/WxShop/CompanyGoods/del', // 删除企业商品
    companyGoodsInfo: '/WxShop/CompanyGoods/info', // 公司商品详情
    addSku: '/WxShop/CompanyGoods/addSku', // 添加商品规格
    editSku: '/WxShop/CompanyGoods/editSku', // 编辑商品规格
    delSku: '/WxShop/CompanyGoods/delSku', // 删除商品规格
    editGoods: '/WxShop/CompanyGoods/edit', // 编辑商品
    CompanyGoodsSkusCpsSubmit: '/WxShop/CompanyGoodsSkusCps/submit', // 提交cps商品
    upIsHot: '/WxShop/CompanyGoodsSkusCps/upIsHot', // 修改爆款商品状态
    delCpsGoods: '/WxShop/CompanyGoodsSkusCps/del', // 删除cps商品信息
    setMercode: '/WxShop/Store/setMercode', // 商家开启话费分佣
    JdcomSettledList: '/WxShop/JdcomSettled/list', // 京东到家列表
    JdcomSettledInfo: '/WxShop/JdcomSettled/info', // 京东到家详情
    JdcomSettledUpStatus: '/WxShop/JdcomSettled/upStatus', // 京东到家更新状态
    shopRegimentalBlack: '/ShopRegimental/isBlack',
    MeituanSettledList: '/WxShop/MeituanSettled/list', // 美团闪购列表
    MeituanSettledInfo: '/WxShop/MeituanSettled/info', // 美团闪购详情
    MeituanSettledUpStatus: '/WxShop/MeituanSettled/upStatus', // 美团闪购家更新状态
    execute: '/WxShop/Store/execute', // 商家列表 执行操作
    upSort: '/WxShop/ChannelsCompany/upSort', // 修改企业入驻排序
    thirdDataList: '/WxShop/TwitterOrder/getList', // 获取第三方数据列表
    getMainList: '/WxShop/TwitterOrder/getMainList', // 中间分页
    getRanking: '/WxShop/TwitterOrder/getRanking', // 第三方 右侧数据排序
    getDayDateList: '/WxShop/TwitterOrder/getDateList', // 每日收益分页
    companyOrder: '/WxShop/CompanyOrder/list', // 企业订单
    companyOrderStateNum: '/WxShop/CompanyOrder/stateNum', // 企业订单数量
    storeBlack: '/WxShop/Store/isBlack',
    trilateralOrder: '/WxShop/JutwitterOrders/getList', //第三方订单
    trilateralCategory: '/WxShop/JutwitterOrders/JutwitterCategory',
    TelCommission: '/WxShop/JutwitterOrders/TelCommission',
    TelCommission2: '/WxShop/JutwitterOrders/ShareCommission',
    Encourage: '/WxShop/JutwitterOrders/encourage',
    isStatisticsFacilitator: '/Facilitator/isStatistics',
    isStatisticsRegimental: '/ShopRegimental/isStatistics',
    isStatisticsStore: '/WxShop/Store/setStatistics',
    copyGoods: '/WxShop/CompanyGoods/copy', //复制商品
    companyList: '/WxShop/CompanyGoods/companyList',
    setAdminStatus: '/WxShop/Withdraw/setAdminStatus',
    companyOrderInfo: '/WxShop/CompanyOrder/info', //企业订单详情
    companyOrderSend: '/WxShop/CompanyOrder/setSend', //企业订单发货
    logisticsQuery: '/WxShop/CompanyOrder/logisticsQuery', //物流信息
    companyOrderCancel: '/WxShop/CompanyOrder/cancel',
    exportWithdraw: '/WxShop/Withdraw/export',
    wxaPlugin: '/Jutwitter/WxCode/wxaPlugin',
    isTencent: '/Facilitator/isTencent',
    isEffectiveMerchants: '/Facilitator/isEffectiveMerchants',
    setWifiPrice: '/Facilitator/setWifiPrice',
    integral: {
      orderStatus: '/WechatIntegralOrder/orderStatus',
      list: '/WechatIntegralOrder/list',
      updateStatus: '/WechatIntegralOrder/updateStatus',
      logisticsQuery: '/WechatIntegralOrder/logisticsQuery',
      info: '/WechatIntegralOrder/info',
      editExpress: '/IntegralOrder/editExpress',
    },
    luckyDrawSetInfo: '/WxShop/LuckyDrawSet/info',
    luckyDrawSet: '/WxShop/LuckyDrawSet/edit',
    luckyDrawAdd: '/WxShop/LuckyDrawSet/add',
    luckyDrawList: '/WxShop/LuckyDraw/getList',
    addLuckyDraw: '/WxShop/LuckyDraw/add',
    editLuckyDraw: '/WxShop/LuckyDraw/edit',
    delLuckyDraw: '/WxShop/LuckyDraw/delete',
    luckyDrawIsopen: '/WxShop/LuckyDraw/isopen',
    luckyDrawInfo: '/WxShop/LuckyDraw/info',
    luckyDrawData: '/WxShop/LuckyDraw/getLuckyDrawJoinList',
    forum: {
      forumsSet: '/WxShop/ForumsSet/add', //论坛设置
      forumsget: '/WxShop/ForumsSet/get', //论坛设置详情
      classifyList: '/WxShop/ForumsPostCate/index', //分类列表
      classifyAdd: '/WxShop/ForumsPostCate/add', //分类添加
      classifyEdit: '/WxShop/ForumsPostCate/edit', //分类编辑
      classifyDelete: '/WxShop/ForumsPostCate/delete', //分类删除
      classifyDetail: '/WxShop/ForumsPostCate/getOne', //分类详情
      postsList: '/WxShop/ForumsPosts/index', //帖子列表
      postsAdd: '/WxShop/ForumsPosts/add', //帖子添加
      postsEdit: '/WxShop/ForumsPosts/edit', //帖子编辑
      postsDetail: '/WxShop/ForumsPosts/getOne', //帖子详情
      postsDelete: '/WxShop/ForumsPosts/delete', //帖子删除
    },
    enterprise: {
      enterpriseCateList: '/WxShop/ChannelsCompanyCategory/list',
      addEnterpriseCate: '/WxShop/ChannelsCompanyCategory/add',
      editEnterpriseCate: '/WxShop/ChannelsCompanyCategory/edit',
      delEnterpriseCate: '/WxShop/ChannelsCompanyCategory/del',
      settlementList: '/WxShop/ChannelsCompany/list',
      settlementAudit: '/WxShop/ChannelsCompany/upStatus',
      addEnterprise: '/WxShop/ChannelsCompany/add',
    },
    Ad: {
      list: '/WxShop/CompanyAd/list',
      status: '/WxShop/CompanyAd/getStatus',
      info: '/WxShop/CompanyAd/info',
      editStatus: '/WxShop/CompanyAd/upStatus',
      del: '/WxShop/CompanyAd/del',
      companyList: '/WxShop/CompanyAd/companyList',
      companyGoodsList: '/WxShop/CompanyAd/companyGoodsList',
      add: '/WxShop/CompanyAd/add',
    },
    videoList: '/WxShop/StoreSettled/videoList', //后台审核贴码视频列表
    upVideoStatus: '/WxShop/StoreSettled/upVideoStatus', //审核
    movementMerch: '/WxShop/Store/movementMerch', //有效激励奖励

    StoreSettledExport: '/WxShop/StoreSettled/export', //商家审核列表导出

    shopCreate: '/WxShop/Store/shopCreate', //同步门店（云昊）

    setAccountAppid: '/WxShop/Store/setAccountAppid', //绑定站点

    setYunhaoDelivery: '/order/setYunhaoDelivery',

    thirdSetInfo: '/OpenWxapp/getInfo',
    thirdSetAdd: '/OpenWxapp/add',
    wxAppList: '/Jutwitter/AllWxppp/getList',
    wxAppListCustom: '/AllWxppp/getLists',

    RechargeList: '/WxShop/Recharge/list',
    rechargeFreight: '/WxShop/Store/rechargeFreight',
  },
  //都能修
  repair: {
    shop: {
      diyinfo: '/Community/ShopDiypage/info',
      diyinfoSet: '/Community/ShopDiypage/diypage',
      ShopPlugin: '/setting/getCommunityShopPlugin',
      Posterinfo: '/Community/SettledPoster/info',
      Posteredit: '/Community/SettledPoster/edit',
    },
    service: {
      ClassifyList: '/Community/ServiceClassify/list',
      ClassifyEditInfo: '/Community/ServiceClassify/editInfo',
      delClassify: '/Community/ServiceClassify/del',
      ClassifyEdit: '/Community/ServiceClassify/edit',
      ClassifyAdd: '/Community/ServiceClassify/add',
      list: '/Community/Service/list',
      add: '/Community/Service/add',
      info: '/Community/Service/info',
      edit: '/Community/Service/edit',
      del: '/Community/Service/del',
      editInfo: '/Community/Service/editInfo',
      orderList: '/Community/ServiceOrder/getList',
      getNum: '/Community/ServiceOrder/getNum',
      orderInfo: '/Community/ServiceOrder/detail',
      cancleOrder: '/Community/ServiceOrder/cancle',
      orderRefundList: '/Community/ServiceOrderRefund/getList',
      orderRefundNum: '/Community/ServiceOrderRefund/getNum',
      orderRefundDeal: '/Community/ServiceOrderRefund/deal',
      orderRefundInfo: '/Community/ServiceOrderRefund/detail',
      orderComment: '/Community/ServiceOrderComment/getList',
      orderCommentReply: '/Community/ServiceOrderComment/reply',
      orderFinanceIncome: '/Community/ServiceOrder/financeIncome',
      communityList: '/Community/ServiceOrder/getCommunityList',
      artisanList: '/Community/ServiceOrder/getAtisanList',
      orderDispatch: '/Community/ServiceOrder/dispatchOrder',
      signBill: '/Community/ServiceOrder/signBill',
    },
    set: {
      feedback: '/Community/UserOpinion/getList',
      kfList: '/Community/kf/getInfo',
      kfAdd: '/Community/kf/add',
      VersionList: '/Community/Version/getList',
      VersionAdd: '/Community/Version/add',
      VersionEdit: '/Community/Version/edit',
      VersionDelete: '/Community/Version/delete',
    },
    user: {
      userList: '/Community/User/getList', // 会员列表
      userDetail: '/Community/User/detail', // 会员详情
      userCardOrderList: '/Community/User/getCardOrderList',
      userSendIntegral: '/Community/User/sendIntegral',
    },
    store: {
      auditList: '/Community/Store/auditList', //商家审核列表
      auditStatus: '/Community/Store/auditStatus', //商家审核
      list: '/Community/Store/list', //商家列表
      info: '/Community/Store/info', //商家详情
      getAccount: '/Community/Store/getAccount', //获取账号
    },
    property: {
      lists: '/Community/Property/lists', //物业列表(无分页)
      list: '/Community/Property/list', //物业列表
      add: '/Community/Property/add', //添加物业
      edit: '/Community/Property/edit', //编辑物业
      del: '/Community/Property/del', //删除物业
      getTemplate: '/Community/Property/getTemplate', //导入模版
      import: '/Community/Property/import', //导入物业
    },
    artisan: {
      userList: '/Community/Artisan/userList', //用户列表
      list: '/Community/Artisan/list', //技工列表
      add: '/Community/Artisan/add', //添加技工
      info: '/Community/Artisan/info', //技工详情
      auditList: '/Community/Artisan/auditList', //技工审核列表
      auditStatus: '/Community/Artisan/auditStatus', //技工审核
      checkInfo: '/Community/Artisan/checkInfo', //技工考核详情
      checkAudit: '/Community/Artisan/checkAudit', //技工考核审核
      insuranceInfo: '/Community/Artisan/insuranceInfo', //技工保险详情
      insuranceAudit: '/Community/Artisan/insuranceAudit', //技工保险审核
      earnestMoneyLog: '/Community/Artisan/earnestMoneyLog', //技工保证金明细
      earnestMoneyDeduct: '/Community/Artisan/earnestMoneyDeduct', //扣除保证金
    },
    marketing: {
      afficheClassifylist: '/Community/AfficheClassify/list', //公告分类列表
      afficheClassifyEditInfo: '/Community/AfficheClassify/editInfo', //快速编辑
      afficheClassifyAdd: '/Community/AfficheClassify/add', //添加公告分类
      afficheClassifyEdit: '/Community/AfficheClassify/edit', //编辑公告分类
      afficheClassifyDel: '/Community/AfficheClassify/del', //删除公告分类
      afficheClassifylists: '/Community/AfficheClassify/lists', //公告分类列表(无分页)
      afficheList: '/Community/Affiche/list', //公告列表
      afficheAdd: '/Community/Affiche/add', //添加公告
      afficheInfo: '/Community/Affiche/info', //公告详情
      afficheEdit: '/Community/Affiche/edit', //编辑公告
      afficheDel: '/Community/Affiche/del', //删除公告
      couponList: '/Community/Coupon/list', //优惠券列表
      cardSetAdd: '/Community/CardSet/add',
      cardSetInfo: '/Community/CardSet/info',
      cardSetEdit: '/Community/CardSet/edit',

      ActivityCateList: '/Community/ActivityCate/index',
      ActivityCateAdd: '/Community/ActivityCate/add',
      ActivityCateEdit: '/Community/ActivityCate/edit',
      ActivityCateDel: '/Community/ActivityCate/delete',
      ActivityList: '/Community/Activity/getList',
      ActivityAdd: '/Community/Activity/add',
      ActivityEdit: '/Community/Activity/edit',
      ActivityUp: '/Community/Activity/up',
      ActivityDel: '/Community/Activity/del',
      ActivityAuditList: '/Community/Activity/getAuditList',
      ActivityAudit: '/Community/Activity/audit',
      ActivityJoinList: '/Community/Activity/getJoinList',
      ActivityVerify: '/Community/Activity/verify',
      ActivityAddIntegral: '/Community/Activity/addIntegral',

      ConsumerCardList: '/Community/ConsumerCard/list',
      ConsumerCardSetStatus: '/Community/ConsumerCard/setStatus',
      ConsumerCardDel: '/Community/ConsumerCard/del',
      ConsumerCardAdd: '/Community/ConsumerCard/add',
      ConsumerCardOrder: '/Community/UserConsumerCard/list',
      ConsumerCardLogList: '/Community/UserConsumerCard/logList',

      purchaseList: '/Community/Wholesale/getList',
      purchaseGoodList: '/Community/Wholesale/getGoodList',
      purchaseGoodAdd: '/Community/Wholesale/add',
      purchaseGoodDel: '/Community/Wholesale/del',
      purchaseGoodInfo: '/Community/Wholesale/info',
      purchaseGoodEdit: '/Community/Wholesale/edit',
    },
    finance: {
      commodityIncome: '/Community/Finance/commodityIncome', //获取商品收益
      fundDetails: '/Community/Finance/fundDetails', //获取平台资金明细
      propertyWithdrawList: '/Community/PropertyWithdraw/getList', //物业对账
      propertyWithdrawDetail: '/Community/PropertyWithdraw/getCashLogList', //物业对账
      earnestMoneyOrderList: '/Community/ArtisanEarnestMoneyOrder/list', //保证金订单
      earnestMoneyOrderExport: '/Community/ArtisanEarnestMoneyOrder/export', //保证金订单导出
      earnestMoneyQuitList: '/Community/ArtisanEarnestMoneyQuit/list', //保证金申退列表
      earnestMoneyQuitExport: '/Community/ArtisanEarnestMoneyQuit/export', //保证金申退列表导出
      earnestMoneyQuitRemit: '/Community/ArtisanEarnestMoneyQuit/remit', //打款
      artisanWithdrawList: '/Community/ArtisanWithdraw/list', //提现列表
      artisanWithdrawAudit: '/Community/ArtisanWithdraw/audit',
      artisanWithdrawExport: '/Community/ArtisanWithdraw/export', //提现列表导出
      getLogDetail: '/Community/PropertyWithdraw/getLogDetail', //对账详情
      checkStatus: '/Community/PropertyWithdraw/checkStatus',
      propertyWithdrawDetailTop: '/Community/PropertyWithdraw/detail', //物业对账
      storeWithdrawList: '/Community/StoreWithdraw/list', //商家提现列表
      storeWithdrawInfo: '/Community/StoreWithdraw/info', //商家提现详情
      storeWithdrawAudit: '/Community/StoreWithdraw/audit', //商家提现审核
      storeWithdrawExport: '/Community/StoreWithdraw/export', //商家提现列表导出
      storeWithdrawInfoList: '/Community/StoreWithdraw/infoList', //提现订单(详情)
      getBankCard: '/Community/PropertyWithdraw/getBankCard', //获取提现银行卡
      remit: '/Community/PropertyWithdraw/remit', //打款
      checkWithdrawStatus: '/Community/PropertyWithdraw/checkWithdrawStatus', //打款
      fundDetailsExport: '/Community/Finance/export',
      commodityIncomeExport: '/Community/Finance/commodityIncomeExport',
    },
    community: {
      lists: '/Community/PropertyCommunity/lists', //小区列表(无分页)
      list: '/Community/PropertyCommunity/list', //小区列表
      add: '/Community/PropertyCommunity/add', //添加小区
      edit: '/Community/PropertyCommunity/edit', //编辑小区
      del: '/Community/PropertyCommunity/del', //删除小区
      getTemplate: '/Community/PropertyCommunity/getTemplate', //导入模版
      import: '/Community/PropertyCommunity/import', //导入小区
    },
    Partner: {
      userList: '/Community/Partner/userList', //用户列表
      list: '/Community/Partner/list', //合伙人列表
      add: '/Community/Partner/add', //添加合伙人
      edit: '/Community/Partner/edit', //编辑合伙人
      del: '/Community/Partner/del', //删除合伙人
      auditList: '/Community/Partner/auditList', //合伙人审核列表
      auditStatus: '/Community/Partner/auditStatus', //合伙人审核
    },
    Subsidiary: {
      lists: '/Community/Subsidiary/lists', //子公司列表(无分页)
      list: '/Community/Subsidiary/list', //子公司列表
      add: '/Community/Subsidiary/add', //添加子公司
      edit: '/Community/Subsidiary/edit', //编辑子公司
      del: '/Community/Subsidiary/del', //删除子公司
    },
    BasicsSet: {
      info: '/Community/BasicsSet/info', //基础设置详情
      edit: '/Community/BasicsSet/edit', //编辑基础设置
    },
    IntegralSet: {
      info: '/Community/IntegralSet/info', //积分设置详情
      edit: '/Community/IntegralSet/edit', //编辑积分设置
      log: '/Community/IntegralLog/list', //积分明细
    },
    Coupon: {
      log: '/Community/UserCouponLog/list', //优惠券明细
      list: '/Community/Coupon/list', //优惠券列表
      add: '/Community/Coupon/add', //添加优惠券
      editStatus: '/Community/Coupon/editStatus', //编辑状态
      del: '/Community/Coupon/del', //删除优惠券
    },
    AdOrder: {
      getStatus: '/Community/AdOrder/getStatus', //广告订单状态
      list: '/Community/AdOrder/list', //广告订单列表
      audit: '/Community/AdOrder/audit', //广告订单审核
    },
    Ad: {
      list: '/Community/Ad/list', //广告列表
      setStatus: '/Community/Ad/setStatus', //设置上架\下架
      setSort: '/Community/Ad/setSort', //编辑排序
      edit: '/Community/Ad/edit',
    },
    index: {
      getData: '/Community/StatisticalData/getData', //获取统计首页
      getDateData: '/Community/StatisticalData/getDateData', //首页获取 按月 按年数据
    },
    goods: {
      PayOrderList: '/Community/PayOrder/list',
    },
  },
  invoice: {
    invoicesList: '/Invoice/list', //开票中心
    invoicesInfo: '/Invoice/info', //开票详情
    invoicesEdit: '/Invoice/editStatus', //开票审核
    sendEmail: '/Invoice/sendEmail', //发送邮箱
  },
};
export default api;
