export default {
  // 正式线
  //  baseurl: 'https://testdigitalize.cnweisou.net', //接口地址
  //  storeurl: 'https://digitalizestore.cnweisou.net/login', //门店正式线地址

  // 测试线
  baseurl: 'https://digitization.5g-center.com', //接口地址
  storeurl: 'https://digitizationstores.5g-center.com/login', //门店测试线地址

  //  baseurl: 'https://tapi.360ep.cn',
};

// 正式线
//export const ServicePath = 'https://admincustomer.cnweisou.net/'; //客服地址
//export const path = 'wss://digitalizesocket.cnweisou.net?access_token='; //socket地址

//// 测试线
export const ServicePath = 'https://admincustomer.5g-gov.com/'; //客服地址
export const path = 'wss://digitizationsocket.5g-center.com?access_token='; //socket地址

export const imgUrlHead = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/';
